import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '../../utils';

const labelVariants = {
  initial: { 
    opacity: 0,
    y: -4
  },
  animate: { 
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: [0.16, 1, 0.3, 1]
    }
  }
};

export function Label({ 
  children, 
  className, 
  required = false,
  htmlFor,
  disabled = false
}) {
  return (
    <motion.label
      initial="initial"
      animate="animate"
      variants={labelVariants}
      htmlFor={htmlFor}
      className={cn(
        "block text-sm font-medium mb-1.5",
        disabled ? "text-gray-400" : "text-gray-700",
        className
      )}
    >
      {children}
      {required && (
        <span className="text-red-500 ml-1" aria-hidden="true">
          *
        </span>
      )}
    </motion.label>
  );
}

// Optional compound components for more complex label patterns
Label.Helper = function LabelHelper({ children, className }) {
  return (
    <motion.p
      initial="initial"
      animate="animate"
      variants={labelVariants}
      className={cn(
        "mt-1 text-sm text-gray-500",
        className
      )}
    >
      {children}
    </motion.p>
  );
};

Label.Error = function LabelError({ children, className }) {
  return (
    <motion.p
      initial="initial"
      animate="animate"
      variants={labelVariants}
      className={cn(
        "mt-1 text-sm text-red-500",
        className
      )}
    >
      {children}
    </motion.p>
  );
};

export default Label;