import React, { useState, useRef, useEffect } from 'react';
import Papa from 'papaparse';
import { createClient } from '@supabase/supabase-js';
import { motion, AnimatePresence } from 'framer-motion';
import {
  MapPin,
  Clock,
  Calendar,
  DollarSign,
  Truck,
  Info,
  Plus,
  X,
  Settings,
  Loader2,
  Upload,
  Table,
  Sparkles,
  HelpCircle,
  Truck as TruckIcon,
  Shirt
} from 'lucide-react';
import { Switch } from "../../ui/switch";
import { Input } from "../../ui/input";
import { Textarea } from "../../ui/text-area";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../ui/select";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from "../../ui/dialog";
import { Button } from "../..//ui/button";
import { ScrollArea } from "../../ui/scroll-area";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../../ui/collapsible";
import { cn } from "../../../utils";
import OtherInfoPanel from "./OtherInfoPanel";
import { ZipcodeSelectionButton } from './ZipcodeSelectionModal';
import ZipcodeCoverageMap from './ZipcodeCoverageMap';
import BulkZipcodeModal from './BulkZipcodeModal';
import { zipBoundariesClient } from '../../../zipBoundariesClient';
import { 
  ZONE_COLORS,
  ZONE_BG_COLORS,
  COLOR_PALETTE, 
  DEFAULT_COLOR,
  DEFAULT_BG_COLOR,
  getBgColor,
  getZoneColor
} from '../../../utils/zoneColors';
import FAQPanel from './FAQPanel';
import ItemPricingModal from './ItemPricingModal';
import CSVMappingModal from './CSVMappingModal';  // Add this import

const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];



const formatTimeDisplay = (time24) => {
  if (!time24) return '';
  try {
    const [hours, minutes] = time24.split(':');
    const date = new Date();
    date.setHours(parseInt(hours), parseInt(minutes));
    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    }).toLowerCase();
  } catch (error) {
    return time24;
  }
};

const TIME_OPTIONS = Array.from({ length: 48 }).map((_, i) => {
  const hour = Math.floor(i / 2);
  const minute = i % 2 === 0 ? '00' : '30';
  const time24 = `${hour.toString().padStart(2, '0')}:${minute}`;
  return {
    value: time24,
    label: formatTimeDisplay(time24)
  };
});

const modalVariants = {
  hidden: { 
    opacity: 0, 
    y: 10,
    scale: 0.98
  },
  visible: { 
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 0.4,
      ease: [0.16, 1, 0.3, 1],
      staggerChildren: 0.1
    }
  },
  exit: { 
    opacity: 0,
    y: -10,
    scale: 0.98,
    transition: {
      duration: 0.3,
      ease: [0.16, 1, 0.3, 1]
    }
  }
};

const itemVariants = {
  hidden: { 
    opacity: 0,
    y: 10,
    scale: 0.98
  },
  visible: { 
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 0.3,
      ease: [0.16, 1, 0.3, 1]
    }
  },
  exit: { 
    opacity: 0,
    x: -10,
    transition: {
      duration: 0.2,
      ease: [0.16, 1, 0.3, 1]
    }
  }
};

// Animations
const tabVariants = {
  initial: { opacity: 0, x: -10 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 10 }
};

const TabButton = ({ active, icon: Icon, label, onClick }) => (
  <motion.button
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    onClick={onClick}
    className={cn(
      "flex items-center gap-2 px-4 py-2 rounded-md transition-colors whitespace-nowrap",
      active 
        ? "bg-gray-900 text-white"
        : "bg-gray-100 text-gray-600 hover:bg-gray-200"
    )}
  >
    <Icon className="w-4 h-4" />
    <span className="text-sm font-medium">{label}</span>
  </motion.button>
);

const ZoneTab = ({
  zone,
  isActive,
  onSelect,
  onDelete,
  isDefault = false,
  style = {}
}) => (
  <motion.div
    initial={{ opacity: 0, x: 20 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: -20 }}
    className={cn(
      "group relative flex items-center gap-2 px-4 py-2 rounded-md cursor-pointer transition-all",
      isActive
        ? isDefault 
          ? "bg-gray-900 text-white" 
          : `text-white`
        : "hover:bg-gray-100"
    )}
    style={{
      ...style,
      backgroundColor: isActive && !isDefault ? style['--zone-color'] : undefined,
      borderColor: isActive && !isDefault ? style['--zone-color'] : undefined
    }}
    onClick={onSelect}
  >
    <span className="text-sm font-medium">
      {isDefault ? 'Default' : zone.name || `Zone ${zone.id}`}
    </span>
    {!isDefault && (
      <button
        onClick={(e) => {
          e.stopPropagation();
          onDelete(zone.id);
        }}
        className="opacity-0 group-hover:opacity-100 p-1 hover:bg-gray-200 rounded-full transition-all"
      >
        <X className="w-4 h-4" />
      </button>
    )}
  </motion.div>
);

// Frequency Discounts Modal
const FrequencyDiscountsModal = ({
  isOpen,
  onClose,
  regularPrice,
  frequencies,
  onSave
}) => {
  const [localFrequencies, setLocalFrequencies] = useState(frequencies);

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open && Object.values(localFrequencies).some(val => val !== '')) {
          if (window.confirm('You have unsaved changes. Are you sure you want to close?')) {
            onClose();
          }
        } else {
          onClose();
        }
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Frequency Discounts</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <span className="text-sm font-medium">One-Time</span>
            <div className="flex items-center gap-1">
              <span className="text-sm text-gray-400">$</span>
              <Input
                type="number"
                value={regularPrice}
                disabled
                className="w-24"
              />
              <span className="text-sm text-gray-600">/lb</span>
            </div>
          </div>

          {['Monthly', 'Biweekly', 'Weekly'].map((frequency) => (
            <div
              key={frequency}
              className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
            >
              <span className="text-sm font-medium">{frequency}</span>
              <div className="flex items-center gap-1">
                <span className="text-sm text-gray-400">$</span>
                <Input
                  type="number"
                  value={localFrequencies[frequency.toLowerCase()] || ''}
                  onChange={(e) => setLocalFrequencies(prev => ({
                    ...prev,
                    [frequency.toLowerCase()]: e.target.value
                  }))}
                  className="w-24"
                  min="0"
                  step="0.01"
                />
                <span className="text-sm text-gray-600">/lb</span>
              </div>
            </div>
          ))}

          <div className="flex justify-end">
            <motion.button
              onClick={() => {
                onSave(localFrequencies);
                onClose();
              }}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="px-4 py-2 bg-gray-900 text-white rounded-md"
            >
              Save Changes
            </motion.button>
          </div>
        </div>
      </DialogContent>
      </Dialog>
    );
  };

// Main PUD Service Panel
const PUDServicePanel = ({
  selectedLocation,
  onUpdateLocation,
  isServiceActive,
  zipBoundariesClient
}) => {
  const [zipData, setZipData] = useState({});
  // All PUD data is read from selectedLocation.services.pickupDelivery
  const pickupDelivery = selectedLocation?.services?.pickupDelivery || {
    general: {
      services: { laundry: true, dryCleaning: false },
      website: '',
      zipcodes: []
    },
    dryCleaning: {
      active: false,
      items: []
    },
    schedule: {
      useSameAsStore: true,
      customerChooseHours: false,
      turnaroundHours: 48,
      sameDayService: false,
      sameDayCutoff: '12:00'
    },
    pricing: {
      regularPriceType: 'fixed_increase',
      regularPrice: 0,
      regularPriceIncrease: 0,
      sameDayPriceType: 'fixed_increase',
      sameDayPrice: 0,
      sameDayPriceIncrease: 0,
      minimumOrder: {
        enabled: false,
        amount: 0,
        priceType: 'fixed_increase',
        increase: 0
      },
      specialtyItems: {
        enabled: false,
        items: [],
        priceType: 'fixed_increase',
        priceIncrease: 0
      },
      extras: {
        enabled: false,
        items: [],
        priceType: 'fixed_increase',
        priceIncrease: 0,
        originalPrice: 0
      },
      dryCleaningItems: {
        enabled: false,
        items: [],
        priceType: 'fixed_increase',
        priceIncrease: 0
      }
    },
    instructions: {
      firstPickup: ''
    },
    zones: [],
    otherInfo: []
  };

  useEffect(() => {
    // Verify client and table
    const checkDatabase = async () => {
      try {
        const { data, error } = await zipBoundariesClient
          .from('zip_boundaries')
          .select('count')
          .limit(1);

        console.log('Database check:', { data, error });
      } catch (err) {
        console.error('Database connection error:', err);
      }
    };

    checkDatabase();
  }, [zipBoundariesClient]);

  // Add this useEffect to fetch data for all zipcodes at once
  useEffect(() => {
    const fetchZipData = async () => {
      if (!zipBoundariesClient) {
        console.error('zipBoundariesClient is not initialized');
        return;
      }

      if (pickupDelivery.general?.zipcodes?.length) {
        try {
          const { data, error } = await zipBoundariesClient
            .from('zip_boundaries')
            .select('zipcode, city, state')
            .in('zipcode', pickupDelivery.general.zipcodes);

          if (!error && data) {
            const zipMap = data.reduce((acc, { zipcode, city, state }) => {
              acc[zipcode] = { city, state };
              return acc;
            }, {});
            setZipData(zipMap);
          }
        } catch (err) {
          console.error('Error fetching zip data:', err);
        }
      }
    };

    fetchZipData();
  }, [pickupDelivery.general?.zipcodes]);

  // These functions will now use the cached data
  const getCityForZipcode = (zipcode) => {
    return zipData[zipcode]?.city || '';
  };

  const getStateForZipcode = (zipcode) => {
    return zipData[zipcode]?.state || '';
  };

  // UI states
  const [activeZoneId, setActiveZoneId] = useState('default');
  const [activeTab, setActiveTab] = useState('general');
  const [showFrequencyModal, setShowFrequencyModal] = useState(false);
  const [showSpecialtyModal, setShowSpecialtyModal] = useState(false);
  const [showExtrasModal, setShowExtrasModal] = useState(false);
  const [showDryCleaningModal, setShowDryCleaningModal] = useState(false);
  const [otherInfoTab, setOtherInfoTab] = useState('additional');

  const [zipInput, setZipInput] = useState('');
  const [zipError, setZipError] = useState('');
  const [showAllZipcodes, setShowAllZipcodes] = useState(false);
  const [showBulkImportModal, setShowBulkImportModal] = useState(false);

  if (!isServiceActive) {
    return (
      <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
        <p className="text-sm text-gray-600 text-center">
          Enable Pickup & Delivery service to configure its settings
        </p>
      </div>
    );
  }

  // Zones array
  const zones = pickupDelivery.zones || [];

  // -------------------------------------------------------------------
  // Add/delete zone
  // -------------------------------------------------------------------
  const handleAddZone = () => {
    // Get next sequential ID based on existing zones
    const maxId = zones.reduce((max, zone) => {
      const zoneId = typeof zone.id === 'number' ? zone.id : parseInt(zone.id, 10);
      return isNaN(zoneId) ? max : Math.max(max, zoneId);
    }, 0);

    const newZoneId = maxId + 1;  // Next sequential ID

    const newZone = {
      id: newZoneId,
      name: `Zone ${newZoneId}`,
      zipcodes: [],
      scheduleSameAsDefault: true,
      pricingSameAsDefault: true,
      instructionsSameAsDefault: true,
      services: {
        laundry: true,
        dryCleaning: true
      },
      schedule: {
        format: 'windows',
        turnaroundHours: 48,
        sameDayService: false,
        sameDayCutoff: '12:00'
      },
      pricing: {
        regularPriceType: 'fixed_increase',
        regularPrice: 0,
        regularPriceIncrease: 0,
        sameDayPriceType: 'fixed_increase',
        sameDayPrice: 0,
        sameDayPriceIncrease: 0,
        minimumOrderType: 'fixed_increase',
        minimumOrder: {
          enabled: false,
          amount: 0,
          priceType: 'fixed_increase',
          increase: 0
        },
        specialtyItemsType: 'fixed_increase',
        specialtyItems: {
          enabled: false,
          items: [],
          priceType: 'fixed_increase',
          priceIncrease: 0,
          originalPrice: 0
        },
        extrasType: 'fixed_increase',
        extras: {
          enabled: false,
          items: [],
          priceType: 'fixed_increase',
          priceIncrease: 0,
          originalPrice: 0
        },
        dryCleaningItemsType: 'fixed_increase',
        dryCleaningItems: {
          enabled: false,
          items: [],
          priceType: 'fixed_increase',
          priceIncrease: 0,
          originalPrice: 0
        }
      },
      instructions: {
        firstPickup: ''
      },
      otherInfo: [],
      faqs: []
    };

    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        pickupDelivery: {
          ...pickupDelivery,
          zones: [...zones, newZone]
        }
      }
    });
    setActiveZoneId(newZoneId);
    setActiveTab('general');
  };

  const handleDeleteZone = (zoneId) => {
    const updatedZones = zones.filter(z => z.id !== zoneId);
    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        pickupDelivery: {
          ...pickupDelivery,
          zones: updatedZones
        }
      }
    });
    if (activeZoneId === zoneId) {
      setActiveZoneId('default');
    }
  };

  // -------------------------------------------------------------------
  // Helper: reassign a zipcode from currentZoneId → targetZoneId
  // -------------------------------------------------------------------
  const handleZipcodeZoneChange = (zipcode, targetZoneId, currentZoneId) => {
    console.log('handleZipcodeZoneChange called with:', {
      zipcode,
      targetZoneId,
      currentZoneId
    });

    // 1) Convert zone IDs from string => number, unless they're 'default'
    const tID = (targetZoneId === 'default') ? 'default' : parseInt(targetZoneId, 10);
    const cID = (currentZoneId === 'default') ? 'default' : parseInt(currentZoneId, 10);

    // 2) Clone arrays
    let updatedZones = [...zones];
    let updatedDefaultZips = [...(pickupDelivery.general?.zipcodes || [])];

    // Log pre-state
    console.log('Before update:');
    console.log('   default zips =', updatedDefaultZips);
    zones.forEach(z => {
      console.log(`   zone ${z.id} =>`, z.zipcodes);
    });

    // 1) remove from old location
    if (cID === 'default') {
      updatedDefaultZips = updatedDefaultZips.filter(z => z !== zipcode);
    } else {
      updatedZones = updatedZones.map(z => {
        if (z.id === cID) {
          return {
            ...z,
            zipcodes: z.zipcodes.filter(code => code !== zipcode)
          };
        }
        return z;
      });
    }

    // 2) add to new location
    if (tID === 'default') {
      updatedDefaultZips.push(zipcode);
    } else {
      updatedZones = updatedZones.map(z => {
        if (z.id === tID) {
          return {
            ...z,
            zipcodes: [...z.zipcodes, zipcode]
          };
        }
        return z;
      });
    }


    // Log post-state
    console.log('After update:');
    console.log('   default zips =', updatedDefaultZips);
    updatedZones.forEach(z => {
      console.log(`   zone ${z.id} =>`, z.zipcodes);
    });

    // 3) push the updated arrays back up
    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        pickupDelivery: {
          ...pickupDelivery,
          general: {
            ...pickupDelivery.general,
            zipcodes: updatedDefaultZips
          },
          zones: updatedZones
        }
      }
    });
  };

  // -------------------------------------------------------------------
  // "Default" tab sections: general, schedule, pricing, instructions, otherInfo
  // -------------------------------------------------------------------
  const handleToggleLaundryService = (checked) => {
    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        pickupDelivery: {
          ...pickupDelivery,
          general: {
            ...pickupDelivery.general,
            services: {
              ...pickupDelivery.general?.services,
              laundry: checked
            }
          }
        }
      }
    });
  };

  const handleToggleDryCleaningService = (checked) => {
    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        pickupDelivery: {
          ...pickupDelivery,
          general: {
            ...pickupDelivery.general,
            services: {
              ...pickupDelivery.general?.services,
              dryCleaning: checked
            }
          },
          dryCleaning: {
            ...(pickupDelivery.dryCleaning || {}),
            active: checked,
            items: checked ? (pickupDelivery.dryCleaning?.items || []) : []
          }
        }
      }
    });
  };

  const handleSaveDryCleaningItems = (items) => {
    // Update PUD dry cleaning items while preserving original drop-off prices
    const dropOffItems = pickupDelivery.dryCleaning?.pricing?.items || [];
    const updatedItems = items.map(item => {
      const dropOffItem = dropOffItems.find(di => di.name === item.name);
      return {
        ...item,
        originalPrice: dropOffItem?.price || item.price // Store original price
      };
    });

    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        pickupDelivery: {
          ...pickupDelivery,
          pricing: {
            ...pickupDelivery.pricing,
            dryCleaningItems: {
              ...pickupDelivery.pricing.dryCleaningItems,
              items: updatedItems
            }
          }
        }
      }
    });
    setShowDryCleaningModal(false);
  };

  const renderDryCleaningContent = () => {
    const dropOffEnabled = selectedLocation?.services?.dropOff?.active;
    const dropOffDryCleaning = selectedLocation?.services?.dropOff?.dryCleaning;
    const pricing = pickupDelivery.pricing || {};

    return (
      <div className="space-y-6">
        {/* Dry Cleaning Items */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-3">
            <Sparkles className="w-5 h-5 text-gray-400" />
            <div className="flex flex-col">
              <span className="text-sm font-medium text-gray-700">Dry Cleaning Items</span>
              {pricing?.dryCleaningItems?.enabled && (
                <span className="text-xs text-gray-500">
                  {pricing?.dryCleaningItems?.items.length} items configured
                </span>
              )}
              {dropOffEnabled && dropOffDryCleaning?.active && (
                <span className="text-xs text-gray-500">
                  Drop-off items: {dropOffDryCleaning.pricing?.items?.length} configured
                </span>
              )}
        </div>
          </div>
          <div className="flex items-center gap-4">
            {pricing?.dryCleaningItems?.enabled && dropOffEnabled && dropOffDryCleaning?.active && (
              <div className="flex items-center gap-4">
                <motion.button
                  onClick={() => {
                    const value = pricing.dryCleaningItems.priceType === 'same_as_dropoff' ? 'fixed_increase' : 'same_as_dropoff';
                    const baseItems = dropOffDryCleaning.pricing?.items || [];
                    onUpdateLocation({
                      ...selectedLocation,
                      services: {
                        ...selectedLocation.services,
                        pickupDelivery: {
                          ...pickupDelivery,
                          pricing: {
                            ...pickupDelivery.pricing,
                            dryCleaningItems: {
                              ...pickupDelivery.pricing.dryCleaningItems,
                              priceType: value,
                              items: baseItems.map(item => ({
                                ...item,
                                price: value === 'same_as_dropoff' ? item.price : (item.price + (pricing.dryCleaningItems?.priceIncrease || 0)),
                                originalPrice: item.price
                              }))
                            }
                          }
                        }
                      }
                    });
                  }}
                  className={cn(
                    "inline-flex items-center gap-2 h-9 px-3 rounded-md transition-all duration-200",
                    pricing.dryCleaningItems.priceType === 'fixed_increase'
                      ? "bg-gray-900 text-white hover:bg-gray-800 shadow-sm hover:shadow" 
                      : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                  )}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <span className="text-sm font-medium">
                    {pricing.dryCleaningItems.priceType === 'fixed_increase' ? 'Fixed Increase' : 'Same as Drop Off'}
                  </span>
                </motion.button>
                {pricing.dryCleaningItems.priceType === 'fixed_increase' && (
                  <div className="flex items-center gap-1">
                    <span className="text-sm text-gray-400">$</span>
                    <Input
                      type="number"
                      value={pricing.dryCleaningItems.priceIncrease || 0}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value) || 0;
                        onUpdateLocation({
                          ...selectedLocation,
                          services: {
                            ...selectedLocation.services,
                            pickupDelivery: {
                              ...pickupDelivery,
                              pricing: {
                                ...pickupDelivery.pricing,
                                dryCleaningItems: {
                                  ...pickupDelivery.pricing.dryCleaningItems,
                                  priceIncrease: value,
                                  items: (dropOffDryCleaning.pricing?.items || []).map(item => ({
                                    ...item,
                                    price: item.price + value,
                                    originalPrice: item.price
                                  }))
                                }
                              }
                            }
                          }
                        });
                      }}
                      className="w-24"
                      min="0"
                      step="0.01"
                    />
                  </div>
                )}
              </div>
            )}
            <Switch
              checked={pricing?.dryCleaningItems?.enabled}
              onCheckedChange={(checked) => {
                const baseItems = dropOffEnabled && dropOffDryCleaning?.active ? 
                  (dropOffDryCleaning.pricing?.items || []) : [];
                onUpdateLocation({
                  ...selectedLocation,
                  services: {
                    ...selectedLocation.services,
                    pickupDelivery: {
                      ...pickupDelivery,
                      pricing: {
                        ...pickupDelivery.pricing,
                        dryCleaningItems: {
                          ...pickupDelivery.pricing.dryCleaningItems,
                          enabled: checked,
                          items: checked ? baseItems.map(item => ({
                            ...item,
                            originalPrice: item.price,
                            price: item.price // Initially set to same as drop-off
                          })) : [],
                          priceType: 'same_as_dropoff' // Default to same as drop-off
                        }
                      }
                    }
                  }
                });
              }}
            />
          </div>
        </div>

        {/* Display Items */}
        {pricing?.dryCleaningItems?.items?.length > 0 && (
          <div className="space-y-3">
            {pricing.dryCleaningItems.items.map((item, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200"
              >
                <span className="text-sm font-medium text-gray-900">{item.name}</span>
                <div className="flex items-center gap-2">
                  {item.originalPrice && item.originalPrice !== item.price && (
                    <span className="text-sm text-gray-400">
                      (Base: ${item.originalPrice})
                    </span>
                  )}
                <span className="text-sm text-gray-600">${item.price}</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const handleZipcodeSelect = (zipcode) => {
    console.log('Adding zipcode:', zipcode);
    console.log('Current zipcodes:', pickupDelivery.general?.zipcodes);

    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        pickupDelivery: {
          ...pickupDelivery,
          general: {
            ...pickupDelivery.general,
            zipcodes: [...(pickupDelivery.general?.zipcodes || []), zipcode]
          }
        }
      }
    });

    console.log('Updated zipcodes:', [...(pickupDelivery.general?.zipcodes || []), zipcode]);
  };

  const renderDefaultGeneral = () => {
    const { general } = pickupDelivery;

    return (
      <div className="grid grid-cols-2 gap-6">
        <div className="space-y-6">
          {/* Services Offered */}
          <div className="space-y-4">
            <h3 className="text-sm font-medium text-gray-900">Services Offered</h3>
            <div className="flex items-center gap-4">
              <div className="flex-1 flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                <span className="text-sm text-gray-700">Laundry Service</span>
                <Switch
                  checked={general?.services?.laundry}
                  onCheckedChange={handleToggleLaundryService}
                />
              </div>
              <div className="flex-1 flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                <span className="text-sm text-gray-700">Dry Cleaning Service</span>
                <Switch
                  checked={general?.services?.dryCleaning}
                  onCheckedChange={handleToggleDryCleaningService}
                />
              </div>
            </div>
          </div>

          {/* Online Ordering Website */}
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-900">
              Online Ordering Website
            </label>
            <Input
              type="url"
              value={general?.website || ''}
              onChange={(e) => {
                onUpdateLocation({
                  ...selectedLocation,
                  services: {
                    ...selectedLocation.services,
                    pickupDelivery: {
                      ...pickupDelivery,
                      general: {
                        ...pickupDelivery.general,
                        website: e.target.value
                      }
                    }
                  }
                });
              }}
              placeholder="https://your-ordering-website.com"
            />
          </div>

          {/* Coverage Areas */}
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-900">
              Coverage Areas
            </label>

            {/* Zipcode Input and Bulk Import */}
            <div className="grid grid-cols-[1fr_auto_auto] gap-2">
              <form 
                className="flex col-span-2 gap-2"
                onSubmit={(e) => {
                  e.preventDefault();
                  console.log('Form submitted with zipcode:', zipInput);

                  // Check for valid zipcode format
                  if (zipInput.length === 5 && /^\d+$/.test(zipInput)) {
                    // Check if zipcode already exists in general zipcodes
                    if (pickupDelivery.general?.zipcodes?.includes(zipInput)) {
                      setZipError('This zipcode is already in your default zone');
                      return;
                    }

                    // Check if zipcode exists in any other zone
                    const existingInZone = zones.find(zone => 
                      zone.zipcodes?.includes(zipInput)
                    );

                    if (existingInZone) {
                      setZipError(`This zipcode is already in zone "${existingInZone.name || `Zone ${existingInZone.id}`}"`);
                      return;
                    }

                    // If we get here, the zipcode is unique
                    handleZipcodeSelect(zipInput);
                    setZipInput('');
                    setZipError('');
                  } else {
                    setZipError('Please enter a valid 5-digit zipcode');
                  }
                }}
              >
                <div className="flex-1">
                  <Input
                    type="text"
                    value={zipInput}
                    onChange={(e) => {
                      setZipInput(e.target.value);
                      if (zipError) setZipError('');
                    }}
                    placeholder="Enter 5-digit zipcode"
                    maxLength={5}
                    className={cn(zipError && "border-red-500")}
                  />
                  {zipError && (
                    <p className="text-xs text-red-500 mt-1">{zipError}</p>
                  )}
                </div>

                <Button 
                  type="submit"
                  variant="default"
                  className="bg-gray-900 text-white hover:bg-gray-800"
                >
                  Add
                </Button>
              </form>

              <Button
                variant="outline"
                onClick={() => setShowBulkImportModal(true)}
                className="flex items-center gap-2"
              >
                <Upload className="w-4 h-4" />
                <span>Bulk Updates</span>
              </Button>
            </div>

            {/* Zipcode Pills */}
            {pickupDelivery.general?.zipcodes?.length > 0 && (
              <div className="mt-4">
                <div className="flex flex-wrap gap-2 max-h-[72px] overflow-hidden relative">
                  {pickupDelivery.general.zipcodes.slice(0, 7).map((zip) => (
                    <div
                      key={zip}
                      className="px-3 py-1 bg-blue-50 text-blue-700 rounded-full text-sm flex items-center gap-2"
                    >
                      {zip}
                      <button
                        onClick={() => {
                          onUpdateLocation({
                            ...selectedLocation,
                            services: {
                              ...selectedLocation.services,
                              pickupDelivery: {
                                ...pickupDelivery,
                                general: {
                                  ...pickupDelivery.general,
                                  zipcodes: pickupDelivery.general.zipcodes.filter(z => z !== zip)
                                }
                              }
                            }
                          });
                        }}
                        className="hover:text-blue-900"
                      >
                        <X className="w-3 h-3" />
                      </button>
                    </div>
                  ))}
                  {pickupDelivery.general.zipcodes.length > 7 && (
                    <div className="px-3 py-1 bg-gray-100 text-gray-600 rounded-full text-sm">
                      +{pickupDelivery.general.zipcodes.length - 7} more
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Bulk Import Modal */}
          <BulkZipcodeModal
            isOpen={showBulkImportModal}
            onClose={() => setShowBulkImportModal(false)}
            onImport={(zipcodes) => {
              const updatedZipcodes = [
                ...(pickupDelivery.general?.zipcodes || []),
                ...zipcodes
              ];
              onUpdateLocation({
                ...selectedLocation,
                services: {
                  ...selectedLocation.services,
                  pickupDelivery: {
                    ...pickupDelivery,
                    general: {
                      ...pickupDelivery.general,
                      zipcodes: updatedZipcodes
                    }
                  }
                }
              });
            }}
            onDelete={(zipcode) => {
              onUpdateLocation({
                ...selectedLocation,
                services: {
                  ...selectedLocation.services,
                  pickupDelivery: {
                    ...pickupDelivery,
                    general: {
                      ...pickupDelivery.general,
                      zipcodes: pickupDelivery.general.zipcodes.filter(z => z !== zipcode)
                    }
                  }
                }
              });
            }}
            existingZipcodes={pickupDelivery.general?.zipcodes || []}
            zipcodeData={(pickupDelivery.general?.zipcodes || []).map(zipcode => ({
              zipcode,
              city: getCityForZipcode(zipcode),
              state: getStateForZipcode(zipcode)
            }))}
          />
        </div>

        {/* Map container - give it proper height and ensure parent fills space */}
              <div className="h-full min-h-[600px]">
                <ZipcodeCoverageMap
                  zipcodes={[
                    ...(pickupDelivery.general?.zipcodes || []),
                    ...zones.flatMap(zone => zone.zipcodes || [])
                  ]}
                  zones={zones}
                  className="h-full"
                  zipBoundariesClient={zipBoundariesClient}  // Add this prop
                />
              </div>
            </div>
          );
        };

  const renderDefaultSchedule = () => {
    const { schedule } = pickupDelivery;
    return (
      <div className="space-y-6">
        {/* Turnaround Time */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-3">
            <Clock className="w-5 h-5 text-gray-400" />
            <div className="flex flex-col">
            <span className="text-sm font-medium text-gray-700">
                Turnaround Time
              </span>
              <span className="text-xs text-gray-500">
                How long it typically takes to process an order
            </span>
          </div>
          </div>
          <div className="flex items-center gap-2">
            <Input
              type="number"
              value={schedule?.turnaroundHours || 48}
              onChange={(e) => {
              onUpdateLocation({
                ...selectedLocation,
                services: {
                  ...selectedLocation.services,
                  pickupDelivery: {
                    ...pickupDelivery,
                    schedule: {
                      ...pickupDelivery.schedule,
                        turnaroundHours: parseInt(e.target.value) || 48
                    }
                  }
                }
              });
            }}
              className="w-20"
              min="1"
          />
            <span className="text-sm text-gray-600">hours</span>
          </div>
        </div>

        {/* Scheduling Format Toggle */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-3">
            <Clock className="w-5 h-5 text-gray-400" />
            <div className="flex flex-col">
              <span className="text-sm font-medium text-gray-700">
                Scheduling Format
              </span>
              <span className="text-xs text-gray-500">
                Choose how you want to set your pickup/delivery times
              </span>
            </div>
          </div>
          <Select
            value={schedule?.format || 'windows'}
            onValueChange={(value) => {
                onUpdateLocation({
                  ...selectedLocation,
                  services: {
                    ...selectedLocation.services,
                    pickupDelivery: {
                      ...pickupDelivery,
                      schedule: {
                        ...pickupDelivery.schedule,
                      format: value
                      }
                    }
                  }
                });
            }}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="windows">Time Windows</SelectItem>
              <SelectItem value="custom">Custom Hours</SelectItem>
            </SelectContent>
          </Select>
        </div>

        {/* Day-by-day Schedule */}
        <div className="space-y-4">
          {DAYS_OF_WEEK.map((day) => {
            const dayKey = day.toLowerCase();
            const dayData = schedule[dayKey] || {};

              return (
                <div
                  key={day}
                className="p-4 bg-gray-50 rounded-lg"
                >
                  <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <span className="text-sm font-medium text-gray-900">{day}</span>
                    {dayData.isOpen && (
                      <span className="text-xs text-gray-500">
                        {schedule.format === 'windows' 
                          ? `${dayData.windows?.length || 0} windows`
                          : formatTimeDisplay(dayData.timeRanges?.[0]?.start) + ' - ' + formatTimeDisplay(dayData.timeRanges?.[0]?.end)
                        }
                      </span>
                    )}
                  </div>
                    <Switch
                      checked={!!dayData.isOpen}
                    onCheckedChange={(checked) => {
                      onUpdateLocation({
                        ...selectedLocation,
                        services: {
                          ...selectedLocation.services,
                          pickupDelivery: {
                            ...pickupDelivery,
                            schedule: {
                              ...pickupDelivery.schedule,
                              [dayKey]: {
                                isOpen: checked,
                                windows: checked && schedule.format === 'windows' 
                                  ? [{ name: 'Morning', start: '09:00', end: '12:00' }]
                                  : [],
                                timeRanges: checked && schedule.format === 'custom'
                                  ? [{ start: '09:00', end: '17:00' }]
                                  : []
                              }
                            }
                          }
                        }
                      });
                    }}
                    />
                  </div>

                  {dayData.isOpen && (
                  <AnimatePresence mode="wait">
                    <motion.div
                      key={schedule.format}
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.2 }}
                      className="mt-4"
                    >
                    {schedule.format === 'windows' ? (
                      // Time Windows Mode
                      <div className="space-y-4">
                        {(dayData.windows || []).map((window, index) => (
                            <motion.div
                              key={index}
                              initial={{ opacity: 0, x: -10 }}
                              animate={{ opacity: 1, x: 0 }}
                              exit={{ opacity: 0, x: 10 }}
                              className="grid grid-cols-[1fr_auto_auto_auto] gap-4 items-center"
                            >
                            <Input
                                placeholder="Window name (e.g. Morning)"
                              value={window.name}
                              onChange={(e) => {
                                const updatedWindows = [...(dayData.windows || [])];
                                updatedWindows[index] = { ...window, name: e.target.value };
                                onUpdateLocation({
                                  ...selectedLocation,
                                  services: {
                                    ...selectedLocation.services,
                                    pickupDelivery: {
                                      ...pickupDelivery,
                                      schedule: {
                                        ...pickupDelivery.schedule,
                                        [dayKey]: {
                                          ...dayData,
                                          windows: updatedWindows
                                        }
                                      }
                                    }
                                  }
                                });
                              }}
                              className="w-full"
                            />
                        <Select
                              value={window.start}
                          onValueChange={(value) => {
                                const updatedWindows = [...(dayData.windows || [])];
                                updatedWindows[index] = { ...window, start: value };
                            onUpdateLocation({
                              ...selectedLocation,
                              services: {
                                ...selectedLocation.services,
                                pickupDelivery: {
                                  ...pickupDelivery,
                                  schedule: {
                                    ...pickupDelivery.schedule,
                                    [dayKey]: {
                                      ...dayData,
                                          windows: updatedWindows
                                    }
                                  }
                                }
                              }
                            });
                          }}
                        >
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            {TIME_OPTIONS.map((time) => (
                              <SelectItem key={time.value} value={time.value}>
                                {time.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                            <span className="text-sm text-gray-500">to</span>
                            <div className="flex items-center gap-2">
                        <Select
                                value={window.end}
                          onValueChange={(value) => {
                                  const updatedWindows = [...(dayData.windows || [])];
                                  updatedWindows[index] = { ...window, end: value };
                            onUpdateLocation({
                              ...selectedLocation,
                              services: {
                                ...selectedLocation.services,
                                pickupDelivery: {
                                  ...pickupDelivery,
                                  schedule: {
                                    ...pickupDelivery.schedule,
                                    [dayKey]: {
                                      ...dayData,
                                            windows: updatedWindows
                                    }
                                  }
                                }
                              }
                            });
                          }}
                        >
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            {TIME_OPTIONS.map((time) => (
                              <SelectItem key={time.value} value={time.value}>
                                {time.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                              <button
                                onClick={() => {
                                  const updatedWindows = dayData.windows.filter((_, i) => i !== index);
                                  onUpdateLocation({
                                    ...selectedLocation,
                                    services: {
                                      ...selectedLocation.services,
                                      pickupDelivery: {
                                        ...pickupDelivery,
                                        schedule: {
                                          ...pickupDelivery.schedule,
                                          [dayKey]: {
                                            ...dayData,
                                            windows: updatedWindows
                                          }
                                        }
                                      }
                                    }
                                  });
                                }}
                                className="p-2 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-full"
                              >
                                <X className="w-4 h-4" />
                              </button>
                      </div>
                            </motion.div>
                        ))}
                        <Button
                          variant="outline"
                          onClick={() => {
                            const updatedWindows = [
                              ...(dayData.windows || []),
                              { name: '', start: '09:00', end: '17:00' }
                            ];
                            onUpdateLocation({
                              ...selectedLocation,
                              services: {
                                ...selectedLocation.services,
                                pickupDelivery: {
                                  ...pickupDelivery,
                                  schedule: {
                                    ...pickupDelivery.schedule,
                                    [dayKey]: {
                                      ...dayData,
                                      windows: updatedWindows
                                    }
                                  }
                                }
                              }
                            });
                          }}
                          className="w-full mt-2"
                        >
                          <Plus className="w-4 h-4 mr-2" />
                          Add Window
                        </Button>
                    </div>
                    ) : (
                      // Custom Hours Mode
                      <div className="grid grid-cols-2 gap-4">
                        <div className="space-y-2">
                          <label className="text-xs text-gray-600">Opening Time</label>
                          <Select
                            value={dayData.timeRanges?.[0]?.start || '09:00'}
                            onValueChange={(value) => {
              onUpdateLocation({
                ...selectedLocation,
                services: {
                  ...selectedLocation.services,
                  pickupDelivery: {
                    ...pickupDelivery,
                    schedule: {
                      ...pickupDelivery.schedule,
                                      [dayKey]: {
                                        ...dayData,
                                        timeRanges: [{ start: value, end: dayData.timeRanges?.[0]?.end || '17:00' }]
                                      }
                    }
                  }
                }
              });
            }}
                          >
                            <SelectTrigger>
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                              {TIME_OPTIONS.map((time) => (
                                <SelectItem key={time.value} value={time.value}>
                                  {time.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
        </div>

          <div className="space-y-2">
                          <label className="text-xs text-gray-600">Closing Time</label>
                          <Select
                            value={dayData.timeRanges?.[0]?.end || '17:00'}
                            onValueChange={(value) => {
                onUpdateLocation({
                  ...selectedLocation,
                  services: {
                    ...selectedLocation.services,
                    pickupDelivery: {
                      ...pickupDelivery,
                      schedule: {
                        ...pickupDelivery.schedule,
                                      [dayKey]: {
                                        ...dayData,
                                        timeRanges: [{ start: dayData.timeRanges?.[0]?.start || '09:00', end: value }]
                                      }
                      }
                    }
                  }
                });
              }}
                          >
                            <SelectTrigger>
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                              {TIME_OPTIONS.map((time) => (
                                <SelectItem key={time.value} value={time.value}>
                                  {time.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
          </div>
                      </div>
                    )}
                    </motion.div>
                  </AnimatePresence>
                )}
              </div>
            );
          })}
        </div>

        {/* Same-Day Service */}
        <div className="space-y-4">
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <Clock className="w-5 h-5 text-gray-400" />
              <span className="text-sm font-medium text-gray-700">Offer Same-Day Service?</span>
            </div>
            <Switch
              checked={schedule?.sameDayService}
              onCheckedChange={(checked) => {
                onUpdateLocation({
                  ...selectedLocation,
                  services: {
                    ...selectedLocation.services,
                    pickupDelivery: {
                      ...pickupDelivery,
                      schedule: {
                        ...pickupDelivery.schedule,
                        sameDayService: checked
                      }
                    }
                  }
                });
              }}
            />
          </div>

          {schedule?.sameDayService && (
            <div className="p-4 bg-gray-50 rounded-lg space-y-2">
              <label className="text-sm font-medium text-gray-700">Same-Day Cutoff Time</label>
              <Select
                value={schedule?.sameDayCutoff || '12:00'}
                onValueChange={(value) => {
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        schedule: {
                          ...pickupDelivery.schedule,
                          sameDayCutoff: value
                        }
                      }
                    }
                  });
                }}
              >
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {TIME_OPTIONS.map((time) => (
                    <SelectItem key={time.value} value={time.value}>
                      {time.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderDefaultPricing = () => {
    const { pricing = {} } = pickupDelivery;
    const dropOffEnabled = selectedLocation?.services?.dropOff?.active;
    const dropOffLaundry = selectedLocation?.services?.dropOff?.laundry;
    const dropOffDryCleaning = selectedLocation?.services?.dropOff?.dryCleaning;

    // Initialize default values if not set
    if (!pricing.regularPriceType) {
      pricing.regularPriceType = 'fixed_increase';
    }
    if (!pricing.sameDayPriceType) {
      pricing.sameDayPriceType = 'fixed_increase';
    }
    if (!pricing.minimumOrder) {
      pricing.minimumOrder = {
        enabled: false,
        amount: 0,
        priceType: 'fixed_increase',
        increase: 0
      };
    }
    if (!pricing.specialtyItems) {
      pricing.specialtyItems = {
        enabled: false,
        items: [],
        priceType: 'fixed_increase',
        priceIncrease: 0
      };
    }
    if (!pricing.extras) {
      pricing.extras = {
        enabled: false,
        items: [],
        priceType: 'fixed_increase',
        priceIncrease: 0,
        originalPrice: 0
      };
    }
    if (!pricing.dryCleaningItems) {
      pricing.dryCleaningItems = {
        enabled: false,
        items: [],
        priceType: 'fixed_increase',
        priceIncrease: 0
      };
    }

    return (
      <div className="space-y-6">
        {/* Regular Price */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-3">
            <DollarSign className="w-5 h-5 text-gray-400" />
            <div className="flex flex-col">
              <span className="text-sm font-medium text-gray-700">Regular Price (per lb)</span>
              {dropOffEnabled && dropOffLaundry?.active && (
                <span className="text-xs text-gray-500">
                  Drop-off price: ${dropOffLaundry.pricing?.regularPerPound || 0}/lb
                </span>
              )}
            </div>
          </div>
          {dropOffEnabled && dropOffLaundry?.active ? (
            <div className="flex items-center gap-4">
              <Select
                value={pricing.regularPriceType || 'fixed_increase'}
                onValueChange={(value) => {
              onUpdateLocation({
                ...selectedLocation,
                services: {
                  ...selectedLocation.services,
                  pickupDelivery: {
                    ...pickupDelivery,
                    pricing: {
                      ...pickupDelivery.pricing,
                          regularPriceType: value,
                          regularPrice: value === 'fixed_increase' 
                            ? (pricing.regularPriceIncrease || 0)
                            : (dropOffLaundry.pricing?.regularPerPound || 0)
                        }
                      }
                    }
                  });
                }}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue>
                    {pricing.regularPriceType === 'custom' ? 'Custom Price' : 'Fixed Increase'}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="fixed_increase">Fixed Increase</SelectItem>
                  <SelectItem value="custom">Custom Price</SelectItem>
                </SelectContent>
              </Select>
              <div className="flex items-center gap-1">
                <span className="text-sm text-gray-400">$</span>
                <Input
                  type="number"
                  value={
                    pricing.regularPriceType === 'custom'
                      ? pricing.regularPrice || 0
                      : pricing.regularPriceIncrease || 0
                  }
                  onChange={(e) => {
                    const value = parseFloat(e.target.value) || 0;
                    onUpdateLocation({
                      ...selectedLocation,
                      services: {
                        ...selectedLocation.services,
                        pickupDelivery: {
                          ...pickupDelivery,
                          pricing: {
                            ...pickupDelivery.pricing,
                            [pricing.regularPriceType === 'custom' ? 'regularPrice' : 'regularPriceIncrease']: value
                    }
                  }
                }
              });
            }}
                  className="w-24"
                  min="0"
                  step="0.01"
                />
                {pricing.regularPriceType === 'fixed_increase' && (
                  <span className="text-sm text-gray-500">
                    = ${((dropOffLaundry.pricing?.regularPerPound || 0) + (pricing.regularPriceIncrease || 0)).toFixed(2)}/lb
                  </span>
                )}
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-1">
              <span className="text-sm text-gray-400">$</span>
              <Input
                type="number"
                value={pricing?.regularPrice || 0}
                onChange={(e) => {
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        pricing: {
                          ...pickupDelivery.pricing,
                          regularPrice: parseFloat(e.target.value) || 0
                        }
                      }
                    }
                  });
                }}
                className="w-32"
                min="0"
                step="0.01"
              />
            </div>
          )}
        </div>

        {/* Minimum Order */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-3">
            <DollarSign className="w-5 h-5 text-gray-400" />
            <div className="flex flex-col">
              <span className="text-sm font-medium text-gray-700">Minimum Order Required</span>
              {dropOffEnabled && dropOffLaundry?.active && dropOffLaundry.pricing?.minimumOrder?.enabled && (
                <span className="text-xs text-gray-500">
                  Drop-off minimum: ${dropOffLaundry.pricing?.minimumOrder?.amount || 0}
                </span>
              )}
            </div>
          </div>
          <div className="flex items-center gap-4">
            {dropOffEnabled && dropOffLaundry?.active ? (
              <div className="flex items-center gap-4">
              <Select
                  value={pricing.minimumOrder?.priceType || 'fixed_increase'}
                onValueChange={(value) => {
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        pricing: {
                          ...pickupDelivery.pricing,
                          minimumOrder: {
                            ...pickupDelivery.pricing.minimumOrder,
                              priceType: value,
                              amount: value === 'fixed_increase' 
                                ? (pricing.minimumOrder?.priceIncrease || 0)
                                : (dropOffLaundry.pricing?.minimumOrder?.amount || 0)
                          }
                        }
                      }
                    }
                  });
                }}
              >
                  <SelectTrigger className="w-[180px]">
                  <SelectValue>
                      {pricing.minimumOrder?.priceType === 'custom' ? 'Custom Amount' : 'Fixed Increase'}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="fixed_increase">Fixed Increase</SelectItem>
                    <SelectItem value="custom">Custom Amount</SelectItem>
                </SelectContent>
              </Select>
              <div className="flex items-center gap-1">
                  <span className="text-sm text-gray-400">$</span>
                <Input
                  type="number"
                    value={
                      pricing.minimumOrder?.priceType === 'custom'
                        ? pricing.minimumOrder?.amount || 0
                        : pricing.minimumOrder?.priceIncrease || 0
                    }
                  onChange={(e) => {
                      const value = parseFloat(e.target.value) || 0;
                    onUpdateLocation({
                      ...selectedLocation,
                      services: {
                        ...selectedLocation.services,
                        pickupDelivery: {
                          ...pickupDelivery,
                          pricing: {
                            ...pickupDelivery.pricing,
                            minimumOrder: {
                              ...pickupDelivery.pricing.minimumOrder,
                                [pricing.minimumOrder?.priceType === 'custom' ? 'amount' : 'priceIncrease']: value
                            }
                          }
                        }
                      }
                    });
                  }}
                    className="w-24"
                  min="0"
                  step="0.01"
                />
                  {pricing.minimumOrder?.priceType === 'fixed_increase' && (
                    <span className="text-sm text-gray-500">
                      = ${((dropOffLaundry.pricing?.minimumOrder?.amount || 0) + (pricing.minimumOrder?.priceIncrease || 0)).toFixed(2)}
                    </span>
                )}
              </div>
            </div>
            ) : (
          <div className="flex items-center gap-1">
            <span className="text-sm text-gray-400">$</span>
            <Input
              type="number"
                  value={pricing?.minimumOrder?.amount || 0}
              onChange={(e) => {
                onUpdateLocation({
                  ...selectedLocation,
                  services: {
                    ...selectedLocation.services,
                    pickupDelivery: {
                      ...pickupDelivery,
                      pricing: {
                        ...pickupDelivery.pricing,
                            minimumOrder: {
                              ...pickupDelivery.pricing.minimumOrder,
                              amount: parseFloat(e.target.value) || 0
                            }
                      }
                    }
                  }
                });
              }}
              className="w-32"
              min="0"
              step="0.01"
            />
              </div>
            )}
          </div>
        </div>

        {/* Same Day Price */}
        {pickupDelivery.schedule?.sameDayService && (
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <Clock className="w-5 h-5 text-gray-400" />
              <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-700">Same-Day Price (per lb)</span>
                {dropOffEnabled && dropOffLaundry?.active && dropOffLaundry.pricing?.sameDay?.enabled && (
                  <span className="text-xs text-gray-500">
                    Drop-off price: ${dropOffLaundry.pricing?.sameDay?.pricePerPound || 0}/lb
                  </span>
                )}
              </div>
            </div>
            {dropOffEnabled && dropOffLaundry?.active ? (
              <div className="flex items-center gap-4">
                <Select
                  value={pricing.sameDayPriceType || 'fixed_increase'}
                  onValueChange={(value) => {
                    onUpdateLocation({
                      ...selectedLocation,
                      services: {
                        ...selectedLocation.services,
                        pickupDelivery: {
                          ...pickupDelivery,
                          pricing: {
                            ...pickupDelivery.pricing,
                            sameDayPriceType: value,
                            sameDayPrice: value === 'fixed_increase' 
                              ? (pricing.sameDayPriceIncrease || 0)
                              : (dropOffLaundry.pricing?.sameDay?.pricePerPound || 0)
                          }
                        }
                      }
                    });
                  }}
                >
                  <SelectTrigger className="w-[180px]">
                    <SelectValue>
                      {pricing.sameDayPriceType === 'custom' ? 'Custom Price' : 'Fixed Increase'}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="fixed_increase">Fixed Increase</SelectItem>
                    <SelectItem value="custom">Custom Price</SelectItem>
                  </SelectContent>
                </Select>
            <div className="flex items-center gap-1">
              <span className="text-sm text-gray-400">$</span>
              <Input
                type="number"
                    value={
                      pricing.sameDayPriceType === 'custom'
                        ? pricing.sameDayPrice || 0
                        : pricing.sameDayPriceIncrease || 0
                    }
                onChange={(e) => {
                      const value = parseFloat(e.target.value) || 0;
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        pricing: {
                          ...pickupDelivery.pricing,
                              [pricing.sameDayPriceType === 'custom' ? 'sameDayPrice' : 'sameDayPriceIncrease']: value
                        }
                      }
                    }
                  });
                }}
                    className="w-24"
                min="0"
                step="0.01"
              />
                  {pricing.sameDayPriceType === 'fixed_increase' && (
                    <span className="text-sm text-gray-500">
                      = ${((dropOffLaundry.pricing?.sameDay?.pricePerPound || 0) + (pricing.sameDayPriceIncrease || 0)).toFixed(2)}/lb
                  </span>
                )}
              </div>
            </div>
            ) : (
              <div className="flex items-center gap-1">
                <span className="text-sm text-gray-400">$</span>
                <Input
                  type="number"
                  value={pricing?.sameDayPrice || 0}
                  onChange={(e) => {
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        pricing: {
                          ...pickupDelivery.pricing,
                            sameDayPrice: parseFloat(e.target.value) || 0
                        }
                      }
                    }
                  });
                }}
                  className="w-32"
                  min="0"
                  step="0.01"
              />
            </div>
            )}
          </div>
        )}

          {/* Specialty Items */}
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <Settings className="w-5 h-5 text-gray-400" />
              <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-700">
                  Specialty Item Pricing
                </span>
                {pricing?.specialtyItems?.enabled && (
                  <span className="text-xs text-gray-500">
                    {pricing?.specialtyItems?.items.length} items configured
                  </span>
                )}
                {dropOffEnabled && dropOffLaundry?.active && dropOffLaundry.pricing?.specialtyItems?.enabled && (
                  <span className="text-xs text-gray-500">
                    Drop-off items: {dropOffLaundry.pricing?.specialtyItems?.items.length} configured
                  </span>
                )}
              </div>
            </div>
            <div className="flex items-center gap-4">
              {pricing?.specialtyItems?.enabled && dropOffEnabled && dropOffLaundry?.active && dropOffLaundry.pricing?.specialtyItems?.enabled && (
                <div className="flex items-center gap-4">
                  <motion.button
                    onClick={() => {
                      const value = pricing.specialtyItems.priceType === 'same_as_dropoff' ? 'fixed_increase' : 'same_as_dropoff';
                      const baseItems = dropOffLaundry.pricing?.specialtyItems?.items || [];
                      onUpdateLocation({
                        ...selectedLocation,
                        services: {
                          ...selectedLocation.services,
                          pickupDelivery: {
                            ...pickupDelivery,
                            pricing: {
                              ...pickupDelivery.pricing,
                              specialtyItems: {
                                ...pickupDelivery.pricing.specialtyItems,
                                priceType: value,
                                items: baseItems.map(item => ({
                                  ...item,
                                  price: value === 'same_as_dropoff' ? item.price : (item.price + (pricing.specialtyItems?.priceIncrease || 0)),
                                  originalPrice: item.price
                                }))
                              }
                            }
                          }
                        }
                      });
                    }}
                    className={cn(
                      "inline-flex items-center gap-2 h-9 px-3 rounded-md transition-all duration-200",
                      pricing.specialtyItems.priceType === 'fixed_increase'
                        ? "bg-gray-900 text-white hover:bg-gray-800 shadow-sm hover:shadow" 
                        : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                    )}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <span className="text-sm font-medium">
                      {pricing.specialtyItems.priceType === 'fixed_increase' ? 'Fixed Increase' : 'Same as Drop Off'}
                    </span>
                  </motion.button>
                  {pricing.specialtyItems.priceType === 'fixed_increase' && (
                    <div className="flex items-center gap-1">
                      <span className="text-sm text-gray-400">$</span>
                      <Input
                        type="number"
                        value={pricing.specialtyItems.priceIncrease || 0}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value) || 0;
                          onUpdateLocation({
                            ...selectedLocation,
                            services: {
                              ...selectedLocation.services,
                              pickupDelivery: {
                                ...pickupDelivery,
                                pricing: {
                                  ...pickupDelivery.pricing,
                                  specialtyItems: {
                                    ...pickupDelivery.pricing.specialtyItems,
                                    priceIncrease: value,
                                    items: (dropOffLaundry.pricing?.specialtyItems?.items || []).map(item => ({
                                      ...item,
                                      price: item.price + value,
                                      originalPrice: item.price
                                    }))
                                  }
                                }
                              }
                            }
                          });
                        }}
                        className="w-24"
                        min="0"
                        step="0.01"
                      />
                    </div>
                  )}
                </div>
              )}
              {pricing?.specialtyItems?.enabled && !(dropOffEnabled && dropOffLaundry?.active && dropOffLaundry.pricing?.specialtyItems?.enabled) && (
                <motion.button
                  onClick={() => setShowSpecialtyModal(true)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="text-sm text-gray-600 hover:text-gray-900"
                >
                  Configure
                </motion.button>
              )}
              <Switch
                checked={pricing?.specialtyItems?.enabled}
                onCheckedChange={(checked) => {
                  const baseItems = dropOffEnabled && dropOffLaundry?.active && dropOffLaundry.pricing?.specialtyItems?.enabled ? 
                    (dropOffLaundry.pricing?.specialtyItems?.items || []) : [];
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        pricing: {
                          ...pickupDelivery.pricing,
                          specialtyItems: {
                            ...(pickupDelivery.pricing?.specialtyItems || {}),
                            enabled: checked,
                            items: checked ? baseItems.map(item => ({
                              ...item,
                              originalPrice: item.price,
                              price: item.price
                            })) : [],
                            priceType: 'same_as_dropoff'
                          }
                        }
                      }
                    }
                  });
                }}
              />
            </div>
          </div>

          {/* Display Specialty Items */}
          {pricing?.specialtyItems?.items?.length > 0 && (
            <div className="space-y-3">
              {pricing.specialtyItems.items.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200"
                >
                  <span className="text-sm font-medium text-gray-900">{item.name}</span>
                  <div className="flex items-center gap-2">
                    {item.originalPrice && item.originalPrice !== item.price && (
                      <span className="text-sm text-gray-400">
                        (Base: ${item.originalPrice})
                      </span>
                    )}
                    <span className="text-sm text-gray-600">${item.price}</span>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Extras */}
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <Plus className="w-5 h-5 text-gray-400" />
              <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-700">Extras Pricing</span>
                {pricing?.extras?.enabled && (
                  <span className="text-xs text-gray-500">
                    {pricing?.extras?.items.length} extras configured
                  </span>
                )}
                {dropOffEnabled && dropOffLaundry?.active && dropOffLaundry.pricing?.extras?.enabled && (
                  <span className="text-xs text-gray-500">
                    Drop-off extras: {dropOffLaundry.pricing?.extras?.items.length} configured
                  </span>
                )}
              </div>
            </div>
            <div className="flex items-center gap-4">
              {pricing?.extras?.enabled && dropOffEnabled && dropOffLaundry?.active && dropOffLaundry.pricing?.extras?.enabled && (
                <div className="flex items-center gap-4">
                  <motion.button
                    onClick={() => {
                      const value = pricing.extras.priceType === 'same_as_dropoff' ? 'fixed_increase' : 'same_as_dropoff';
                      const baseItems = dropOffLaundry.pricing?.extras?.items || [];
                      onUpdateLocation({
                        ...selectedLocation,
                        services: {
                          ...selectedLocation.services,
                          pickupDelivery: {
                            ...pickupDelivery,
                            pricing: {
                              ...pickupDelivery.pricing,
                              extras: {
                                ...pickupDelivery.pricing.extras,
                                priceType: value,
                                items: baseItems.map(item => ({
                                  ...item,
                                  price: value === 'same_as_dropoff' ? item.price : (item.price + (pricing.extras?.priceIncrease || 0)),
                                  originalPrice: item.price
                                }))
                              }
                            }
                          }
                        }
                      });
                    }}
                    className={cn(
                      "inline-flex items-center gap-2 h-9 px-3 rounded-md transition-all duration-200",
                      pricing.extras.priceType === 'fixed_increase'
                        ? "bg-gray-900 text-white hover:bg-gray-800 shadow-sm hover:shadow" 
                        : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                    )}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <span className="text-sm font-medium">
                      {pricing.extras.priceType === 'fixed_increase' ? 'Fixed Increase' : 'Same as Drop Off'}
                    </span>
                  </motion.button>
                  {pricing.extras.priceType === 'fixed_increase' && (
                    <div className="flex items-center gap-1">
                      <span className="text-sm text-gray-400">$</span>
                      <Input
                        type="number"
                        value={pricing.extras.priceIncrease || 0}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value) || 0;
                          onUpdateLocation({
                            ...selectedLocation,
                            services: {
                              ...selectedLocation.services,
                              pickupDelivery: {
                                ...pickupDelivery,
                                pricing: {
                                  ...pickupDelivery.pricing,
                                  extras: {
                                    ...pickupDelivery.pricing.extras,
                                    priceIncrease: value,
                                    items: (dropOffLaundry.pricing?.extras?.items || []).map(item => ({
                                      ...item,
                                      price: item.price + value,
                                      originalPrice: item.price
                                    }))
                                  }
                                }
                              }
                            }
                          });
                        }}
                        className="w-24"
                        min="0"
                        step="0.01"
                      />
                    </div>
                  )}
                </div>
              )}
              {pricing?.extras?.enabled && !(dropOffEnabled && dropOffLaundry?.active && dropOffLaundry.pricing?.extras?.enabled) && (
                <motion.button
                  onClick={() => setShowExtrasModal(true)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="text-sm text-gray-600 hover:text-gray-900"
                >
                  Configure
                </motion.button>
              )}
              <Switch
                checked={pricing?.extras?.enabled}
                onCheckedChange={(checked) => {
                  const baseItems = dropOffEnabled && dropOffLaundry?.active && dropOffLaundry.pricing?.extras?.enabled ? 
                    (dropOffLaundry.pricing?.extras?.items || []) : [];
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        pricing: {
                          ...pickupDelivery.pricing,
                          extras: {
                            ...(pickupDelivery.pricing?.extras || {}),
                            enabled: checked,
                            items: checked ? baseItems.map(item => ({
                              ...item,
                              originalPrice: item.price,
                              price: item.price
                            })) : [],
                            priceType: 'same_as_dropoff'
                          }
                        }
                      }
                    }
                  });
                }}
              />
            </div>
          </div>

          {/* Display Extras */}
          {pricing?.extras?.items?.length > 0 && (
            <div className="space-y-3">
              {pricing.extras.items.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200"
                >
                  <span className="text-sm font-medium text-gray-900">{item.name}</span>
                  <div className="flex items-center gap-2">
                    {item.originalPrice && item.originalPrice !== item.price && (
                      <span className="text-sm text-gray-400">
                        (Base: ${item.originalPrice})
                      </span>
                    )}
                    <span className="text-sm text-gray-600">${item.price}</span>
                  </div>
                </div>
              ))}
            </div>
          )}
      </div>
    );
  };

  const renderDefaultInstructions = () => {
    const { instructions } = pickupDelivery;
    return (
      <div className="space-y-6">
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">
            First Pickup Requirements
          </label>
          <Textarea
            value={instructions?.firstPickup || ''}
            onChange={(e) => {
              onUpdateLocation({
                ...selectedLocation,
                services: {
                  ...selectedLocation.services,
                  pickupDelivery: {
                    ...pickupDelivery,
                    instructions: {
                      ...pickupDelivery.instructions,
                      firstPickup: e.target.value
                    }
                  }
                }
              });
            }}
            placeholder="Enter special instructions for first pickup (bags, containers, etc.)"
            className="min-h-[200px]"
          />
        </div>
      </div>
    );
  };

  const renderDefaultOtherInfo = () => {
    return (
      <div className="space-y-6">
        {/* Sub-tabs for Other Info */}
        <div className="flex items-center gap-3">
          <TabButton
            active={otherInfoTab === 'additional'}
            icon={Info}
            label="Additional Info"
            onClick={() => setOtherInfoTab('additional')}
          />
          <TabButton
            active={otherInfoTab === 'faqs'}
            icon={HelpCircle}
            label="FAQs"
            onClick={() => setOtherInfoTab('faqs')}
          />
        </div>

        {/* Content based on selected sub-tab */}
        <AnimatePresence mode="wait">
          <motion.div
            key={otherInfoTab}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {otherInfoTab === 'additional' && (
              <OtherInfoPanel
                value={pickupDelivery.otherInfo || []}
                onChange={(newValue) => {
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        otherInfo: newValue
                      }
                    }
                  });
                }}
                title="Additional Pickup & Delivery Information"
              />
            )}

            {otherInfoTab === 'faqs' && (
              <FAQPanel
                faqs={pickupDelivery.faqs || []}
                onChange={(newFaqs) => {
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        faqs: newFaqs
                      }
                    }
                  });
                }}
                title="Pickup & Delivery FAQs"
              />
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    );
  };

  // -------------------------------------------------------------------
  // Zone-specific logic
  // -------------------------------------------------------------------
  const renderZoneGeneral = (zone) => (
    <div className="grid grid-cols-2 gap-6">
      <div className="space-y-6">
        {/* Zone Name */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">Zone Name</label>
          <Input
            value={zone.name}
            onChange={(e) => {
              const updatedZones = zones.map(z =>
                z.id === zone.id ? { ...z, name: e.target.value } : z
              );
              onUpdateLocation({
                ...selectedLocation,
                services: {
                  ...selectedLocation.services,
                  pickupDelivery: {
                    ...pickupDelivery,
                    zones: updatedZones
                  }
                }
              });
            }}
            placeholder="Enter zone name"
          />
        </div>

        {/* Services Toggles */}
        <div className="flex items-center gap-4">
          <div className="flex-1 flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <Sparkles className="w-5 h-5 text-gray-400" />
              <span className="text-sm font-medium text-gray-700">
                Laundry Service Available
              </span>
            </div>
            <Switch
              checked={zone.services?.laundry ?? true} // Default to true if not set
              onCheckedChange={(checked) => {
                const updatedZones = zones.map(z =>
                  z.id === zone.id ? {
                    ...z,
                    services: {
                      ...z.services,
                      laundry: checked
                    }
                  } : z
                );
                onUpdateLocation({
                  ...selectedLocation,
                  services: {
                    ...selectedLocation.services,
                    pickupDelivery: {
                      ...pickupDelivery,
                      zones: updatedZones
                    }
                  }
                });
              }}
            />
          </div>

          <div className="flex-1 flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <Sparkles className="w-5 h-5 text-gray-400" />
              <span className="text-sm font-medium text-gray-700">
                Dry Cleaning Available
              </span>
            </div>
            <Switch
              checked={zone.services?.dryCleaning ?? true} // Default to true if not set
              onCheckedChange={(checked) => {
                const updatedZones = zones.map(z =>
                  z.id === zone.id ? {
                    ...z,
                    services: {
                      ...z.services,
                      dryCleaning: checked
                    }
                  } : z
                );
                onUpdateLocation({
                  ...selectedLocation,
                  services: {
                    ...selectedLocation.services,
                    pickupDelivery: {
                      ...pickupDelivery,
                      zones: updatedZones
                    }
                  }
                });
              }}
            />
          </div>
        </div>

        {/* Zone Zipcodes */}
        <div className="space-y-4">
          <h3 className="text-sm font-medium text-gray-900">Zone Zipcodes</h3>
          <ZipcodeSelectionButton 
            allZipcodes={[
              ...(pickupDelivery.general?.zipcodes || []),
              ...zones.flatMap(zone => zone.zipcodes || [])
            ]}
            zones={zones}
            activeZoneId={zone.id}
            onZipcodeZoneChange={handleZipcodeZoneChange}
          />
        </div>
      </div>

      <ZipcodeCoverageMap
        zipcodes={[
          ...(pickupDelivery.general?.zipcodes || []),
          ...zones.flatMap(zone => zone.zipcodes || [])
        ]}
        zones={zones}
        className="h-full"
        zipBoundariesClient={zipBoundariesClient}  // Add this prop
      />
    </div>
  );

  const renderZoneSchedule = (zone) => {
    const zoneSchedule = zone.schedule || {};
    const defaultSchedule = pickupDelivery.schedule || {};

    const updateZone = (updates) => {
      const updatedZones = zones.map(z =>
        z.id === zone.id ? {
          ...z,
          schedule: {
            ...z.schedule,
            ...updates
          }
        } : z
      );
      onUpdateLocation({
        ...selectedLocation,
        services: {
          ...selectedLocation.services,
          pickupDelivery: {
            ...pickupDelivery,
            zones: updatedZones
          }
        }
      });
    };

    return (
      <div className="space-y-6">
        {/* Override Default Schedule Toggle */}
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
            <Calendar className="w-5 h-5 text-gray-400" />
            <div className="flex flex-col">
              <span className="text-sm font-medium text-gray-700">Override Default Schedule</span>
              <span className="text-xs text-gray-500">Enable to set custom schedule for this zone</span>
            </div>
              </div>
              <Switch
            checked={!zone.scheduleSameAsDefault}
                onCheckedChange={(checked) => {
              const updatedZones = zones.map(z =>
                z.id === zone.id ? { ...z, scheduleSameAsDefault: !checked } : z
              );
              onUpdateLocation({
                ...selectedLocation,
                services: {
                  ...selectedLocation.services,
                  pickupDelivery: {
                    ...pickupDelivery,
                    zones: updatedZones
                  }
                }
              });
                }}
              />
            </div>

        {!zone.scheduleSameAsDefault && (
          <AnimatePresence mode="wait">
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.2 }}
              className="space-y-6"
            >
              {/* Turnaround Time */}
              <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center gap-3">
                  <Clock className="w-5 h-5 text-gray-400" />
                  <div className="flex flex-col">
                    <span className="text-sm font-medium text-gray-700">
                      Turnaround Time
                    </span>
                    <span className="text-xs text-gray-500">
                      How long it typically takes to process an order
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <div className="text-sm text-gray-500">
                    Default: {defaultSchedule?.turnaroundHours || 48} hours
                  </div>
                  <div className="flex items-center gap-2">
                    <Input
                      type="number"
                      value={zoneSchedule?.turnaroundHours || defaultSchedule?.turnaroundHours || 48}
                      onChange={(e) => {
                        updateZone({
                          turnaroundHours: parseInt(e.target.value) || 48
                        });
                      }}
                      className="w-20"
                      min="1"
                    />
                    <span className="text-sm text-gray-600">hours</span>
                  </div>
          </div>
        </div>

            {/* Scheduling Format Toggle */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-3">
            <Clock className="w-5 h-5 text-gray-400" />
                <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-700">
                    Scheduling Format
                  </span>
                  <span className="text-xs text-gray-500">
                    Choose how you want to set your pickup/delivery times
                </span>
                </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="text-sm text-gray-500">
                    Currently using: {zoneSchedule?.format === 'custom' ? 'Custom Hours' : 'Time Windows'}
                </div>
                <Select
                    value={zoneSchedule?.format || defaultSchedule?.format || 'windows'}
                  onValueChange={(value) => {
                    updateZone({ format: value });
                  }}
                >
                  <SelectTrigger className="w-[180px]">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="windows">Time Windows</SelectItem>
                    <SelectItem value="custom">Custom Hours</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>

            {/* Day-by-day Schedule */}
            <div className="space-y-4">
                {DAYS_OF_WEEK.map((day) => {
                  const dayKey = day.toLowerCase();
                  const dayData = zoneSchedule[dayKey] || {};
                  const defaultDayData = defaultSchedule[dayKey] || {};

                  return (
                    <div
                      key={day}
                      className="p-4 bg-gray-50 rounded-lg"
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                        <span className="text-sm font-medium text-gray-900">{day}</span>
                          {dayData.isOpen && (
                            <span className="text-xs text-gray-500">
                              {zoneSchedule.format === 'windows' 
                                ? `${dayData.windows?.length || 0} windows`
                                : formatTimeDisplay(dayData.timeRanges?.[0]?.start) + ' - ' + formatTimeDisplay(dayData.timeRanges?.[0]?.end)
                              }
                            </span>
                          )}
                        </div>
                        <div className="flex items-center gap-4">
                          <div className="text-sm text-gray-500">
                            Default: {defaultDayData.isOpen ? 'Open' : 'Closed'}
            </div>
            <Switch
                            checked={!!dayData.isOpen}
              onCheckedChange={(checked) => {
                updateZone({
                              [dayKey]: {
                                isOpen: checked,
                                  windows: checked && zoneSchedule.format === 'windows'
                                    ? [{ name: 'Morning', start: '09:00', end: '12:00' }]
                                    : [],
                                  timeRanges: checked && zoneSchedule.format === 'custom'
                                    ? [{ start: '09:00', end: '17:00' }]
                                    : []
                              }
                });
              }}
            />
          </div>
        </div>

                      {dayData.isOpen && (
                        <AnimatePresence mode="wait">
          <motion.div
                            key={zoneSchedule.format}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.2 }}
                            className="mt-4"
                          >
                        {zoneSchedule.format === 'windows' ? (
                          // Time Windows Mode
                          <div className="space-y-4">
                            {(dayData.windows || []).map((window, index) => (
                                  <motion.div
                                    key={index}
                                    initial={{ opacity: 0, x: -10 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: 10 }}
                                    className="grid grid-cols-[1fr_auto_auto_auto] gap-4 items-center"
                                  >
              <Input
                                      placeholder="Window name (e.g. Morning)"
                                  value={window.name}
                onChange={(e) => {
                                    const updatedWindows = [...(dayData.windows || [])];
                                    updatedWindows[index] = { ...window, name: e.target.value };
                                    updateZone({
                                      [dayKey]: {
                                        ...dayData,
                                        windows: updatedWindows
                                      }
                                    });
                                  }}
                                  className="w-full"
                                />
                                <Select
                                  value={window.start}
                                  onValueChange={(value) => {
                                    const updatedWindows = [...(dayData.windows || [])];
                                    updatedWindows[index] = { ...window, start: value };
                                    updateZone({
                                      [dayKey]: {
                                        ...dayData,
                                        windows: updatedWindows
                                      }
                                    });
                                  }}
                                >
                                  <SelectTrigger>
                                    <SelectValue />
                                  </SelectTrigger>
                                  <SelectContent>
                                    {TIME_OPTIONS.map((time) => (
                                      <SelectItem key={time.value} value={time.value}>
                                        {time.label}
                                      </SelectItem>
                                    ))}
                                  </SelectContent>
                                </Select>
                                <span className="text-sm text-gray-500">to</span>
                                <div className="flex items-center gap-2">
                                  <Select
                                    value={window.end}
                                    onValueChange={(value) => {
                                      const updatedWindows = [...(dayData.windows || [])];
                                      updatedWindows[index] = { ...window, end: value };
                                      updateZone({
                                        [dayKey]: {
                                          ...dayData,
                                          windows: updatedWindows
                                        }
                                      });
                                    }}
                                  >
                                    <SelectTrigger>
                                      <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                      {TIME_OPTIONS.map((time) => (
                                        <SelectItem key={time.value} value={time.value}>
                                          {time.label}
                                        </SelectItem>
                                      ))}
                                    </SelectContent>
                                  </Select>
                                  <button
                                    onClick={() => {
                                      const updatedWindows = dayData.windows.filter((_, i) => i !== index);
                                      updateZone({
                                        [dayKey]: {
                                          ...dayData,
                                          windows: updatedWindows
                                        }
                                      });
                                    }}
                                    className="p-2 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-full"
                                  >
                                    <X className="w-4 h-4" />
                                  </button>
            </div>
          </motion.div>
                            ))}
                            <Button
                              variant="outline"
                              onClick={() => {
                                const updatedWindows = [
                                  ...(dayData.windows || []),
                                  { name: '', start: '09:00', end: '17:00' }
                                ];
                                updateZone({
                                  [dayKey]: {
                                    ...dayData,
                                    windows: updatedWindows
                                  }
                                });
                              }}
                              className="w-full mt-2"
                            >
                              <Plus className="w-4 h-4 mr-2" />
                              Add Window
                            </Button>
                          </div>
                        ) : (
                          // Custom Hours Mode
                        <div className="grid grid-cols-2 gap-4">
                          <div className="space-y-2">
                            <label className="text-xs text-gray-600">Opening Time</label>
                              <Select
                                value={dayData.timeRanges?.[0]?.start || '09:00'}
                                onValueChange={(value) => {
                                  updateZone({
                                    [dayKey]: {
                                      ...dayData,
                                        timeRanges: [{ start: value, end: dayData.timeRanges?.[0]?.end || '17:00' }]
                                    }
                                  });
                                }}
                              >
                                <SelectTrigger>
                                  <SelectValue />
                                </SelectTrigger>
                                <SelectContent>
                                  {TIME_OPTIONS.map((time) => (
                                    <SelectItem key={time.value} value={time.value}>
                                      {time.label}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                          </div>

                          <div className="space-y-2">
                            <label className="text-xs text-gray-600">Closing Time</label>
                              <Select
                                value={dayData.timeRanges?.[0]?.end || '17:00'}
                                onValueChange={(value) => {
                                  updateZone({
                                    [dayKey]: {
                                      ...dayData,
                                        timeRanges: [{ start: dayData.timeRanges?.[0]?.start || '09:00', end: value }]
                                    }
                                  });
                                }}
                              >
                                <SelectTrigger>
                                  <SelectValue />
                                </SelectTrigger>
                                <SelectContent>
                                  {TIME_OPTIONS.map((time) => (
                                    <SelectItem key={time.value} value={time.value}>
                                      {time.label}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                          </div>
                          </div>
                        )}
                        </motion.div>
                        </AnimatePresence>
                      )}
                    </div>
                  );
                })}
            </div>

            {/* Same-Day Service */}
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <Clock className="w-5 h-5 text-gray-400" />
                  <span className="text-sm font-medium text-gray-700">Offer Same-Day Service?</span>
            </div>
            <div className="flex items-center gap-4">
              <div className="text-sm text-gray-500">
                    Default: {defaultSchedule?.sameDayService ? 'Yes' : 'No'}
              </div>
              <Switch
                    checked={!!zoneSchedule?.sameDayService}
                onCheckedChange={(checked) => {
                  updateZone({
                        sameDayService: checked,
                        sameDayCutoff: checked ? (defaultSchedule?.sameDayCutoff || '12:00') : undefined
                  });
                }}
              />
            </div>
          </div>

              {zoneSchedule?.sameDayService && (
                <div className="p-4 bg-gray-50 rounded-lg space-y-2">
                  <label className="text-sm font-medium text-gray-700">Same-Day Cutoff Time</label>
                <div className="flex items-center gap-4">
                  <div className="text-sm text-gray-500">
                      Default: {formatTimeDisplay(defaultSchedule?.sameDayCutoff)}
                  </div>
                  <Select
                      value={zoneSchedule?.sameDayCutoff || '12:00'}
                    onValueChange={(value) => {
                      updateZone({ sameDayCutoff: value });
                    }}
                  >
                      <SelectTrigger>
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      {TIME_OPTIONS.map((time) => (
                        <SelectItem key={time.value} value={time.value}>
                          {time.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            )}
          </motion.div>
          </AnimatePresence>
        )}
      </div>
    );
  };

  const renderZonePricing = (zone) => {
    const zonePricing = zone.pricing || {};
    const defaultPricing = pickupDelivery.pricing || {};
    const dropOffEnabled = selectedLocation?.services?.dropOff?.active;
    const dropOffLaundry = selectedLocation?.services?.dropOff?.laundry;
    const dropOffDryCleaning = selectedLocation?.services?.dropOff?.dryCleaning;

    // Initialize default values if not set
    if (!zonePricing.regularPriceType) {
      zonePricing.regularPriceType = 'fixed_increase';
    }
    if (!zonePricing.sameDayPriceType) {
      zonePricing.sameDayPriceType = 'fixed_increase';
    }
    if (!zonePricing.minimumOrderType) {
      zonePricing.minimumOrderType = 'fixed_increase';
    }
    if (!zonePricing.specialtyItemsType) {
      zonePricing.specialtyItemsType = 'fixed_increase';
    }
    if (!zonePricing.extrasType) {
      zonePricing.extrasType = 'fixed_increase';
    }
    if (!zonePricing.dryCleaningItemsType) {
      zonePricing.dryCleaningItemsType = 'fixed_increase';
    }

    const updateZone = (updates) => {
      const updatedZones = zones.map(z =>
        z.id === zone.id ? {
          ...z,
          pricing: {
            ...z.pricing,
            ...updates
          }
        } : z
      );
      onUpdateLocation({
        ...selectedLocation,
        services: {
          ...selectedLocation.services,
          pickupDelivery: {
            ...pickupDelivery,
            zones: updatedZones
          }
        }
      });
    };

    const getBasePrice = (type) => {
      if (!dropOffEnabled || !dropOffLaundry?.active) {
        return defaultPricing[type] || 0;
      }
      switch (type) {
        case 'regularPrice':
          return dropOffLaundry.pricing?.regularPerPound || 0;
        case 'sameDayPrice':
          return dropOffLaundry.pricing?.sameDay?.pricePerPound || 0;
        case 'minimumOrder':
          return dropOffLaundry.pricing?.minimumOrder?.amount || 0;
        case 'specialtyItems':
          return dropOffLaundry.pricing?.specialtyItems?.items || [];
        case 'extras':
          return dropOffLaundry.pricing?.extras?.items || [];
        case 'dryCleaningItems':
          return dropOffDryCleaning?.pricing?.items || [];
        default:
          return 0;
      }
    };

    return (
      <div className="space-y-4">
        {/* Regular Price */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-3">
            <DollarSign className="w-5 h-5 text-gray-400" />
            <div className="flex flex-col">
              <span className="text-sm font-medium text-gray-700">Regular Price (per lb)</span>
              <span className="text-xs text-gray-500">
                Base price: ${getBasePrice('regularPrice')}/lb
              </span>
          </div>
            </div>
          <div className="flex items-center gap-4">
            <Select
              value={zonePricing.regularPriceType || 'fixed_increase'}
              onValueChange={(value) => {
                updateZone({
                  regularPriceType: value,
                  regularPrice: value === 'fixed_increase' 
                    ? (zonePricing.regularPriceIncrease || 0)
                    : getBasePrice('regularPrice')
                });
              }}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue>
                  {zonePricing.regularPriceType === 'custom' ? 'Custom Price' : 'Fixed Increase'}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="fixed_increase">Fixed Increase</SelectItem>
                <SelectItem value="custom">Custom Price</SelectItem>
              </SelectContent>
            </Select>
            <div className="flex items-center gap-1">
              <span className="text-sm text-gray-400">$</span>
              <Input
                type="number"
                value={
                  zonePricing.regularPriceType === 'custom'
                    ? zonePricing.regularPrice || 0
                    : zonePricing.regularPriceIncrease || 0
                }
                onChange={(e) => {
                  const value = parseFloat(e.target.value) || 0;
                  updateZone({
                    [zonePricing.regularPriceType === 'custom' ? 'regularPrice' : 'regularPriceIncrease']: value
                  });
                }}
                className="w-24"
                min="0"
                step="0.01"
              />
              {zonePricing.regularPriceType === 'fixed_increase' && (
                <span className="text-sm text-gray-500">
                  = ${(getBasePrice('regularPrice') + (zonePricing.regularPriceIncrease || 0)).toFixed(2)}/lb
                </span>
              )}
            </div>
          </div>
        </div>

        {/* Same Day Price */}
        {defaultPricing.sameDay?.enabled && (
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <Clock className="w-5 h-5 text-gray-400" />
              <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-700">Same Day Price (per lb)</span>
                <span className="text-xs text-gray-500">
                  Base price: ${getBasePrice('sameDayPrice')}/lb
                </span>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <Select
                value={zonePricing.sameDayPriceType || 'fixed_increase'}
                onValueChange={(value) => {
                  updateZone({
                    sameDayPriceType: value,
                    'sameDay.price': value === 'fixed_increase'
                      ? (zonePricing.sameDay?.priceIncrease || 0)
                      : getBasePrice('sameDayPrice')
                  });
                }}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue>
                    {zonePricing.sameDayPriceType === 'custom' ? 'Custom Price' : 'Fixed Increase'}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="fixed_increase">Fixed Increase</SelectItem>
                  <SelectItem value="custom">Custom Price</SelectItem>
                </SelectContent>
              </Select>
            <div className="flex items-center gap-1">
              <span className="text-sm text-gray-400">$</span>
              <Input
                type="number"
                  value={
                    zonePricing.sameDayPriceType === 'custom'
                      ? zonePricing.sameDay?.price || 0
                      : zonePricing.sameDay?.priceIncrease || 0
                  }
                onChange={(e) => {
                    const value = parseFloat(e.target.value) || 0;
                    updateZone({
                      sameDay: {
                        ...zonePricing.sameDay,
                        [zonePricing.sameDayPriceType === 'custom' ? 'price' : 'priceIncrease']: value
                      }
                    });
                  }}
                  className="w-24"
                min="0"
                step="0.01"
              />
                {zonePricing.sameDayPriceType === 'fixed_increase' && (
                  <span className="text-sm text-gray-500">
                    = ${(getBasePrice('sameDayPrice') + (zonePricing.sameDay?.priceIncrease || 0)).toFixed(2)}/lb
                  </span>
                )}
            </div>
            </div>
          </div>
        )}

        {/* Minimum Order */}
        {defaultPricing.minimumOrder?.enabled && (
            <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
              <div className="flex items-center gap-3">
                <DollarSign className="w-5 h-5 text-gray-400" />
              <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-700">Minimum Order</span>
                <span className="text-xs text-gray-500">
                  Base minimum: ${getBasePrice('minimumOrder')}
                </span>
              </div>
              </div>
              <div className="flex items-center gap-4">
              <Select
                value={zonePricing.minimumOrderType || 'fixed_increase'}
                onValueChange={(value) => {
                  updateZone({
                    minimumOrderType: value,
                    'minimumOrder.amount': value === 'fixed_increase'
                      ? (zonePricing.minimumOrder?.increase || 0)
                      : getBasePrice('minimumOrder')
                  });
                }}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue>
                    {zonePricing.minimumOrderType === 'custom' ? 'Custom Amount' : 'Fixed Increase'}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="fixed_increase">Fixed Increase</SelectItem>
                  <SelectItem value="custom">Custom Amount</SelectItem>
                </SelectContent>
              </Select>
              <div className="flex items-center gap-1">
                <span className="text-sm text-gray-400">$</span>
                <Input
                  type="number"
                  value={
                    zonePricing.minimumOrderType === 'custom'
                      ? zonePricing.minimumOrder?.amount || 0
                      : zonePricing.minimumOrder?.increase || 0
                  }
                  onChange={(e) => {
                    const value = parseFloat(e.target.value) || 0;
                    updateZone({
                      minimumOrder: {
                        ...zonePricing.minimumOrder,
                        [zonePricing.minimumOrderType === 'custom' ? 'amount' : 'increase']: value
                      }
                    });
                  }}
                  className="w-24"
                  min="0"
                  step="0.01"
                />
                {zonePricing.minimumOrderType === 'fixed_increase' && (
                  <span className="text-sm text-gray-500">
                    = ${(getBasePrice('minimumOrder') + (zonePricing.minimumOrder?.increase || 0)).toFixed(2)}
                  </span>
                )}
                </div>
            </div>
          </div>
        )}

        {/* Specialty Items */}
        {defaultPricing.specialtyItems?.enabled && (
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <Settings className="w-5 h-5 text-gray-400" />
              <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-700">Specialty Items</span>
                <span className="text-xs text-gray-500">
                  Base items: {getBasePrice('specialtyItems').length} configured
                </span>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-600">Fixed Increase</span>
                <Switch
                  checked={zonePricing.specialtyItemsType === 'fixed_increase'}
                  onCheckedChange={(checked) => {
                    const value = checked ? 'fixed_increase' : 'same_as_dropoff';
                    const baseItems = getBasePrice('specialtyItems');
                    updateZone({
                      specialtyItemsType: value,
                      specialtyItems: {
                        ...zonePricing.specialtyItems,
                        priceType: value,
                        items: baseItems.map(item => ({
                          ...item,
                          price: value === 'same_as_dropoff' ? item.price : (item.price + (zonePricing.specialtyItems?.priceIncrease || 0)),
                          originalPrice: item.price
                        }))
                      }
                    });
                  }}
                />
              </div>
              {zonePricing.specialtyItemsType === 'fixed_increase' && (
                <div className="flex items-center gap-1">
                  <span className="text-sm text-gray-400">$</span>
                  <Input
                    type="number"
                    value={zonePricing.specialtyItems?.priceIncrease || 0}
                    onChange={(e) => {
                      const value = parseFloat(e.target.value) || 0;
                      updateZone({
                        specialtyItems: {
                          ...zonePricing.specialtyItems,
                          priceIncrease: value,
                          items: getBasePrice('specialtyItems').map(item => ({
                            ...item,
                            price: item.price + value,
                            originalPrice: item.price
                          }))
                        }
                      });
                    }}
                    className="w-24"
                    min="0"
                    step="0.01"
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {/* Extras */}
        {defaultPricing.extras?.enabled && (
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <Plus className="w-5 h-5 text-gray-400" />
              <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-700">Extras</span>
                <span className="text-xs text-gray-500">
                  Base items: {getBasePrice('extras').length} configured
                </span>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-600">Fixed Increase</span>
                <Switch
                  checked={zonePricing.extrasType === 'fixed_increase'}
                  onCheckedChange={(checked) => {
                    const value = checked ? 'fixed_increase' : 'same_as_dropoff';
                    const baseItems = getBasePrice('extras');
                    updateZone({
                      extrasType: value,
                      extras: {
                        ...zonePricing.extras,
                        priceType: value,
                        items: baseItems.map(item => ({
                          ...item,
                          price: value === 'same_as_dropoff' ? item.price : (item.price + (zonePricing.extras?.priceIncrease || 0)),
                          originalPrice: item.price
                        }))
                      }
                    });
                  }}
                />
              </div>
              {zonePricing.extrasType === 'fixed_increase' && (
                <div className="flex items-center gap-1">
                  <span className="text-sm text-gray-400">$</span>
                  <Input
                    type="number"
                    value={zonePricing.extras?.priceIncrease || 0}
                    onChange={(e) => {
                      const value = parseFloat(e.target.value) || 0;
                      updateZone({
                        extras: {
                          ...zonePricing.extras,
                          priceIncrease: value,
                          items: getBasePrice('extras').map(item => ({
                            ...item,
                            price: item.price + value,
                            originalPrice: item.price
                          }))
                        }
                      });
                    }}
                    className="w-24"
                    min="0"
                    step="0.01"
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {/* Dry Cleaning Items */}
        {defaultPricing.dryCleaningItems?.enabled && (
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <Shirt className="w-5 h-5 text-gray-400" />
              <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-700">Dry Cleaning Items</span>
                <span className="text-xs text-gray-500">
                  Base items: {getBasePrice('dryCleaningItems').length} configured
                </span>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <motion.button
                onClick={() => {
                  const value = zonePricing.dryCleaningItemsType === 'same_as_dropoff' ? 'fixed_increase' : 'same_as_dropoff';
                  const baseItems = getBasePrice('dryCleaningItems');
                  updateZone({
                    dryCleaningItemsType: value,
                    dryCleaningItems: {
                      ...zonePricing.dryCleaningItems,
                      items: baseItems.map(item => ({
                        ...item,
                        price: value === 'same_as_dropoff' ? item.price : (item.price + (zonePricing.dryCleaningItems?.priceIncrease || 0)),
                        originalPrice: item.price
                      }))
                    }
                  });
                }}
                className={cn(
                  "inline-flex items-center gap-2 h-9 px-3 rounded-md transition-all duration-200",
                  zonePricing.dryCleaningItemsType === 'fixed_increase'
                    ? "bg-gray-900 text-white hover:bg-gray-800 shadow-sm hover:shadow" 
                    : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                )}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <span className="text-sm font-medium">
                  {zonePricing.dryCleaningItemsType === 'fixed_increase' ? 'Fixed Increase' : 'Same as Drop Off'}
                </span>
              </motion.button>
              {zonePricing.dryCleaningItemsType === 'fixed_increase' && (
                <div className="flex items-center gap-1">
                  <span className="text-sm text-gray-400">$</span>
                  <Input
                    type="number"
                    value={zonePricing.dryCleaningItems?.priceIncrease || 0}
                    onChange={(e) => {
                      const value = parseFloat(e.target.value) || 0;
                      updateZone({
                        dryCleaningItems: {
                          ...zonePricing.dryCleaningItems,
                          priceIncrease: value,
                          items: getBasePrice('dryCleaningItems').map(item => ({
                            ...item,
                            price: item.price + value,
                            originalPrice: item.price
                          }))
                        }
                      });
                    }}
                    className="w-24"
                    min="0"
                    step="0.01"
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderZoneOtherInfo = (zone) => {
    return (
      <div className="space-y-6">
        {/* Sub-tabs for Zone Other Info */}
        <div className="flex items-center gap-3">
          <TabButton
            active={otherInfoTab === 'additional'}
            icon={Info}
            label="Additional Info"
            onClick={() => setOtherInfoTab('additional')}
          />
          <TabButton
            active={otherInfoTab === 'faqs'}
            icon={HelpCircle}
            label="FAQs"
            onClick={() => setOtherInfoTab('faqs')}
          />
        </div>

        {/* Content based on selected sub-tab */}
        <AnimatePresence mode="wait">
          <motion.div
            key={otherInfoTab}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {otherInfoTab === 'additional' && (
              <OtherInfoPanel
                value={zone.otherInfo || []}
                onChange={(newValue) => {
                  const updatedZones = zones.map(z =>
                    z.id === zone.id ? { ...z, otherInfo: newValue } : z
                  );
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        zones: updatedZones
                      }
                    }
                  });
                }}
                title={`Additional ${zone.name || `Zone ${zone.id}`} Information`}
              />
            )}

            {otherInfoTab === 'faqs' && (
              <FAQPanel
                faqs={zone.faqs || []}
                onChange={(newFaqs) => {
                  const updatedZones = zones.map(z =>
                    z.id === zone.id ? { ...z, faqs: newFaqs } : z
                  );
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      pickupDelivery: {
                        ...pickupDelivery,
                        zones: updatedZones
                      }
                    }
                  });
                }}
                title={`${zone.name || `Zone ${zone.id}`} FAQs`}
              />
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    );
  };

  const renderZoneInstructions = (zone) => {
    return (
      <div className="space-y-6">
        {/* Custom Instructions Toggle */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-3">
            <TruckIcon className="w-5 h-5 text-gray-400" />
            <div className="flex flex-col">
              <span className="text-sm font-medium text-gray-700">Custom Zone Instructions</span>
              <span className="text-xs text-gray-500">Enable to override default instructions for this zone</span>
            </div>
          </div>
          <Switch
            checked={!zone.instructionsSameAsDefault}
            onCheckedChange={(checked) => {
              const updatedZones = zones.map(z =>
                z.id === zone.id ? { ...z, instructionsSameAsDefault: !checked } : z
              );
              onUpdateLocation({
                ...selectedLocation,
                services: {
                  ...selectedLocation.services,
                  pickupDelivery: {
                    ...pickupDelivery,
                    zones: updatedZones
                  }
                }
              });
            }}
          />
        </div>

        {!zone.instructionsSameAsDefault && (
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <label className="text-sm font-medium text-gray-700">
                First Pickup Requirements
              </label>
              <div className="text-sm text-gray-500">
                Default: {pickupDelivery.instructions?.firstPickup ? 'Set' : 'Not set'}
              </div>
            </div>
            <Textarea
              value={zone.instructions?.firstPickup || ''}
              onChange={(e) => {
                const updatedZones = zones.map(z =>
                  z.id === zone.id ? {
                    ...z,
                    instructions: {
                      ...z.instructions,
                      firstPickup: e.target.value
                    }
                  } : z
                );
                onUpdateLocation({
                  ...selectedLocation,
                  services: {
                    ...selectedLocation.services,
                    pickupDelivery: {
                      ...pickupDelivery,
                      zones: updatedZones
                    }
                  }
                });
              }}
              placeholder="Enter special instructions for first pickup (bags, containers, etc.)"
              className="min-h-[200px]"
            />
          </div>
        )}
      </div>
    );
  };

  // -------------------------------------------------------------------
  // Final return
  // -------------------------------------------------------------------
  return (
    <>
    <div className="space-y-6">
      {/* Zone Tabs */}
      <div className="flex items-center gap-2 overflow-x-auto pb-2">
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          className="flex items-center gap-2"
        >
          <ZoneTab
            isDefault
            isActive={activeZoneId === 'default'}
            onSelect={() => setActiveZoneId('default')}
          />

          {zones.map((zone) => {
            // Force numeric zone ID
            const numericZoneId = typeof zone.id === 'string' ? parseInt(zone.id, 10) : zone.id;
            const zoneColor = getZoneColor(numericZoneId);
            return (
              <ZoneTab
                key={zone.id}
                zone={zone}
                isActive={activeZoneId === zone.id}
                onSelect={() => setActiveZoneId(zone.id)}
                onDelete={handleDeleteZone}
                style={{
                  '--zone-color': zoneColor,
                  '--zone-bg-color': getBgColor(zoneColor)
                }}
              />
            );
          })}

          <motion.button
            onClick={handleAddZone}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="p-2 rounded-md hover:bg-gray-100"
          >
            <Plus className="w-4 h-4 text-gray-600" />
          </motion.button>
        </motion.div>
      </div>

      {/* Default Tab */}
      {activeZoneId === 'default' && (
        <div className="space-y-6">
          <div className="flex items-center gap-3">
            {['general', 'schedule', 'pricing', 'instructions', 'otherInfo'].map((tab) => {
              const Icon = (
                tab === 'general'   ? Settings :
                tab === 'schedule'  ? Calendar :
                tab === 'pricing'   ? DollarSign :
                tab === 'instructions' ? TruckIcon :
                Info
              );
              const label = tab === 'otherInfo' ? 'Other Info' : 
                           tab.charAt(0).toUpperCase() + tab.slice(1);
              return (
                <TabButton
                  key={tab}
                  active={activeTab === tab}
                  icon={Icon}
                  label={label}
                  onClick={() => setActiveTab(tab)}
                />
              );
            })}
            {pickupDelivery.general?.services?.dryCleaning && (
              <TabButton
                active={activeTab === 'dryCleaning'}
                icon={Sparkles}
                label="Dry Cleaning"
                onClick={() => setActiveTab('dryCleaning')}
              />
            )}
          </div>

          <div className="bg-white rounded-lg border border-gray-200 p-6">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeTab}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
              >
                {activeTab === 'general' && renderDefaultGeneral()}
                {activeTab === 'schedule' && renderDefaultSchedule()}
                {activeTab === 'pricing' && renderDefaultPricing()}
                {activeTab === 'instructions' && renderDefaultInstructions()}
                {activeTab === 'dryCleaning' && renderDryCleaningContent()}
                {activeTab === 'otherInfo' && renderDefaultOtherInfo()}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      )}

      {/* Zone-specific Tab */}
      {activeZoneId !== 'default' && (
        <div className="space-y-6">
          <div className="flex items-center gap-3">
            <TabButton
              active={activeTab === 'general'}
              icon={Settings}
              label="General"
              onClick={() => setActiveTab('general')}
            />
            {/* Show dry cleaning tab if this zone has it enabled */}
            {zones.find(z => z.id === activeZoneId)?.services?.dryCleaning && (
              <TabButton
                active={activeTab === 'dryCleaning'}
                icon={Sparkles}
                label="Dry Cleaning"
                onClick={() => setActiveTab('dryCleaning')}
              />
            )}
              <TabButton
                active={activeTab === 'schedule'}
                icon={Calendar}
                label="Schedule"
                onClick={() => setActiveTab('schedule')}
              />
              <TabButton
                active={activeTab === 'pricing'}
                icon={DollarSign}
                label="Pricing"
                onClick={() => setActiveTab('pricing')}
              />
            <TabButton
              active={activeTab === 'instructions'}
              icon={TruckIcon}
              label="Instructions"
              onClick={() => setActiveTab('instructions')}
            />
            <TabButton
              active={activeTab === 'otherInfo'}
              icon={Info}
              label="Other Info"
              onClick={() => setActiveTab('otherInfo')}
            />
          </div>

          <div className="bg-white rounded-lg border border-gray-200 p-6">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeTab}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
              >
                {activeTab === 'general' && renderZoneGeneral(zones.find(z => z.id === activeZoneId))}
                {activeTab === 'schedule' && renderZoneSchedule(zones.find(z => z.id === activeZoneId))}
                {activeTab === 'pricing' && renderZonePricing(zones.find(z => z.id === activeZoneId))}
                {activeTab === 'dryCleaning' && renderDryCleaningContent()}
                {activeTab === 'instructions' && renderZoneInstructions(zones.find(z => z.id === activeZoneId))}
                {activeTab === 'otherInfo' && renderZoneOtherInfo(zones.find(z => z.id === activeZoneId))}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      )}
    </div>
    <ItemPricingModal
      isOpen={showDryCleaningModal}
      onClose={() => setShowDryCleaningModal(false)}
      title="Dry Cleaning Item Pricing"
      items={pickupDelivery.dryCleaning?.items || []}
      onSave={handleSaveDryCleaningItems}
    />
    </>
  );
};

export default PUDServicePanel;