import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Clock, 
  ChevronLeft, 
  ChevronRight, 
  Copy,
  Shield,
  Users,
  Timer,
  Wrench,
  Plus,
  Wind,
  Trash2,
  Settings,
  Wallet, CreditCard, Receipt, Bitcoin, Info, HelpCircle
} from 'lucide-react';
import HolidayHoursModal from './HolidayHoursModal';
import { Switch } from "../../ui/switch";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/select";
import { ConfirmModal } from "../../ui/confirm-modal";
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "../../ui/tooltip";
import { cn } from "../../../utils";
import OtherInfoPanel from "./OtherInfoPanel.js"
import FAQPanel from './FAQPanel';

const serviceCardVariants = {
  initial: { 
    opacity: 0,
    y: 10,
    scale: 0.98
  },
  animate: { 
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 0.4,
      ease: [0.16, 1, 0.3, 1]
    }
  },
  exit: {
    opacity: 0,
    y: -10,
    scale: 0.98,
    transition: {
      duration: 0.3
    }
  }
};

const selectionIndicatorVariants = {
  initial: { 
    opacity: 0,
    scale: 0.98
  },
  animate: { 
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      ease: [0.16, 1, 0.3, 1]
    }
  }
};

const disabledMessageVariants = {
  initial: { 
    opacity: 0,
    height: 0,
    scale: 0.98
  },
  animate: { 
    opacity: 1,
    height: 'auto',
    scale: 1,
    transition: {
      height: {
        duration: 0.4,
        ease: [0.16, 1, 0.3, 1]
      },
      opacity: {
        duration: 0.3,
        delay: 0.1
      },
      scale: {
        duration: 0.4,
        ease: [0.16, 1, 0.3, 1]
      }
    }
  },
  exit: { 
    opacity: 0,
    height: 0,
    scale: 0.98,
    transition: {
      height: {
        duration: 0.3,
        ease: [0.16, 1, 0.3, 1]
      },
      opacity: {
        duration: 0.2
      },
      scale: {
        duration: 0.3
      }
    }
  }
};

const DAYS_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

const generateCapacityOptions = () => {
  const options = [];
  for (let i = 10; i <= 50; i += 5) {
    options.push({ value: i, label: `${i} lbs` });
  }
  for (let i = 60; i <= 120; i += 10) {
    options.push({ value: i, label: `${i} lbs` });
  }
  return options;
};

const CAPACITY_OPTIONS = generateCapacityOptions();

const formatTimeDisplay = (time24) => {
  if (!time24) return '';

  try {
    const [hours, minutes] = time24.split(':');
    const date = new Date();
    date.setHours(parseInt(hours), parseInt(minutes));

    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    }).toLowerCase();
  } catch (error) {
    console.error('Error formatting time:', error);
    return '';
  }
};

const TIME_OPTIONS = Array.from({ length: 48 }).map((_, i) => {
  const hour = Math.floor(i / 2);
  const minute = i % 2 === 0 ? '00' : '30';
  const time24 = `${hour.toString().padStart(2, '0')}:${minute}`;

  return {
    value: time24,
    label: formatTimeDisplay(time24)
  };
});

const validateDayData = (dayData) => {
  if (dayData.hours?.type === 'allDay') {
    if (dayData.lastLoad || dayData.security) {
      console.warn('Invalid configuration: 24/7 location should not have last load or security settings');
      return false;
    }
  }
  return true;
};

const canHaveLastLoad = (dayData) => {
  const hours = dayData?.hours;
  if (!hours || hours.type === 'allDay') return false;
  return hours.isOpen && hours.type === 'specific';
};


// Add this helper function similar to canHaveLastLoad
const canHaveAttendants = (dayData) => {
  const hours = dayData?.hours;
  // Only check if the day is open, regardless of whether it's 24/7 or specific hours
  return hours?.isOpen === true;
};

const canHaveSecurity = (dayData) => {
  const hours = dayData?.hours;
  if (!hours || hours.type === 'allDay') return false;
  return hours.isOpen && hours.type === 'specific';
};



const TabButton = ({ active, icon: Icon, label, onClick }) => (
  <motion.button
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    onClick={onClick}
    className={cn(
      "flex items-center gap-2 px-3 py-2 rounded-md transition-colors shrink-0",
      active 
        ? "bg-gray-900 text-white" 
        : "bg-gray-100 text-gray-600 hover:bg-gray-200"
    )}
  >
    <Icon className="w-4 h-4" />
    {label && <span className="text-sm font-medium whitespace-nowrap">{label}</span>}
  </motion.button>
);

const SelfServicePanel = ({
  selectedLocation,
  onUpdateLocation,
  isServiceActive
}) => {
  const [showHolidayModal, setShowHolidayModal] = useState(false);
  const [currentDayIndex, setCurrentDayIndex] = useState(1);
  const [activeTab, setActiveTab] = useState('hours');
  const [showApplyAllModal, setShowApplyAllModal] = useState(false);
  const [activeMachineType, setActiveMachineType] = useState('washers');
  const [activePaymentType, setActivePaymentType] = useState('cash');
  // Initialize with empty values
  const [rawInputValues, setRawInputValues] = useState({
    laundryCardCost: '',
    machines: {}
  });
  const [otherInfoTab, setOtherInfoTab] = useState('additional');

  const tabsWithDays = ['hours', 'lastLoad', 'attendant', 'security'];
  const showDaySelector = tabsWithDays.includes(activeTab);
  const currentDay = DAYS_OF_WEEK[currentDayIndex];
  const currentDayLower = currentDay.toLowerCase();
  const dayData = selectedLocation.services?.selfServe?.days?.[currentDayLower] || {};
  const machines = selectedLocation?.services?.selfServe?.machines ?? {
    washers: [],
    dryers: []
  };

  const paymentMethods = selectedLocation.services?.selfServe?.paymentMethods ?? {
    cash: { active: false, atmOnSite: false },
    card: { 
      active: false, 
      acceptedCards: { credit: true, debit: true },
      holdInfo: "",
      applicableMachines: {
        washers: true,
        dryers: true,
        vending: false
      }
    },
    laundryCard: {
      active: false,
      howToGet: "",
      howItWorks: "",
      rewards: "",
      cost: ""
    },
    crypto: {
      active: false,
      acceptedCoins: {
        USDC: false,
        USDT: false,
        Bitcoin: false,
        Ethereum: false,
        Sui: false
      }
    }
  };

  useEffect(() => {
    setRawInputValues({
      laundryCardCost: selectedLocation?.services?.selfServe?.paymentMethods?.laundryCard?.cost?.toString() || '',
      machines: {}
    });
  }, [selectedLocation]);

  // When switching machine type
  const handleMachineTypeChange = (type) => {
    setActiveMachineType(type);
    // Ensure the type exists in machines
    if (!machines[type]) {
      onUpdateLocation({
        ...selectedLocation,
        services: {
          ...selectedLocation.services,
          selfServe: {
            ...selectedLocation.services?.selfServe,
            machines: {
              ...machines,
              [type]: []
            }
          }
        }
      });
    }
  };

  const handleUpdatePaymentMethod = (method, updates) => {
    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        selfServe: {
          ...selectedLocation.services.selfServe,
          paymentMethods: {
            ...paymentMethods,
            [method]: {
              ...paymentMethods[method],
              ...updates
            }
          }
        }
      }
    });
  };

  const handleDayDataUpdate = (field, updatedValue) => {
    // First validate the new day data
    const newDayData = {
      ...selectedLocation.services.selfServe.days[currentDayLower],
      [field]: updatedValue
    };

    if (!validateDayData(newDayData)) {
      console.warn('Invalid day data configuration detected');
    }

    // If switching to 24/7 operation, clear incompatible settings
    if (field === 'hours' && updatedValue.type === 'allDay') {
      const clearedData = {
        ...selectedLocation.services.selfServe.days[currentDayLower],
        hours: updatedValue,
        lastLoad: null,
        security: null,
        // Keep existing attendant settings intact
        attendant: selectedLocation.services.selfServe.days[currentDayLower].attendant
      };

      onUpdateLocation({
        ...selectedLocation,
        services: {
          ...selectedLocation.services,
          selfServe: {
            ...selectedLocation.services.selfServe,
            days: {
              ...selectedLocation.services.selfServe.days,
              [currentDayLower]: clearedData
            }
          }
        }
      });
      return; // Exit early as we've handled the update
    }

    // For all other updates, proceed with normal update logic
    // But prevent setting lastLoad or security if we're in 24/7 mode
    const currentHours = selectedLocation.services.selfServe.days[currentDayLower]?.hours;
    if (currentHours?.type === 'allDay' && (field === 'lastLoad' || field === 'security')) {
      console.warn('Cannot set lastLoad or security settings for 24/7 operation');
      return;
    }

    // Proceed with normal update
    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        selfServe: {
          ...selectedLocation.services.selfServe,
          days: {
            ...selectedLocation.services.selfServe.days,
            [currentDayLower]: {
              ...selectedLocation.services.selfServe.days[currentDayLower],
              [field]: updatedValue
            }
          }
        }
      }
    });
  };

  const handleApplyToAllDays = () => {
    const currentDaySettings = selectedLocation.services?.selfServe?.days[currentDay.toLowerCase()];
    const updatedDays = DAYS_OF_WEEK.reduce((acc, day) => {
      acc[day.toLowerCase()] = { ...currentDaySettings };
      return acc;
    }, {});

    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        selfServe: {
          ...selectedLocation.services.selfServe,
          days: updatedDays
        }
      }
    });
    setShowApplyAllModal(false);
  };

  // Machine management functions
  const handleAddMachine = () => {
    const newMachines = {
      washers: [...(machines?.washers || [])],
      dryers: [...(machines?.dryers || [])]
    };
    const machineType = activeMachineType === 'washers' ? 'washer' : 'dryer';
    const baseConfig = {
      id: (newMachines[activeMachineType].length + 1).toString(),
      type: machineType,
      capacity: 10,
      cost: 0.00
    };

    const newMachine = machineType === 'washer'
      ? { ...baseConfig, loadType: 'front' }
      : { ...baseConfig, minutes: 30 };

    newMachines[activeMachineType] = [...newMachines[activeMachineType], newMachine];

    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        selfServe: {
          ...selectedLocation.services.selfServe,
          machines: newMachines
        }
      }
    });
  };

  const handleUpdateMachine = (index, updatedMachine) => {
    const newMachines = { ...machines };
    newMachines[activeMachineType][index] = updatedMachine;

    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        selfServe: {
          ...selectedLocation.services.selfServe,
          machines: newMachines
        }
      }
    });
  };

  const handleDeleteMachine = (index) => {
    const newMachines = { ...machines };
    newMachines[activeMachineType] = newMachines[activeMachineType]
      .filter((_, i) => i !== index)
      .map((machine, i) => ({ ...machine, id: (i + 1).toString() }));

    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        selfServe: {
          ...selectedLocation.services.selfServe,
          machines: newMachines
        }
      }
    });
  };

  

  const renderHoursContent = () => {
    const hours = dayData.hours || {};
    const isOpen = hours.isOpen ?? true;
    const isAllDay = hours.type === 'allDay';
    const timeRange = hours.timeRanges?.[0] || { start: '09:00', end: '17:00' };

    return (
      <motion.div 
        className="space-y-6"
        initial={{ opacity: 0, y: 4 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.3,
          ease: [0.16, 1, 0.3, 1]
        }}
      >
        <div className="flex items-center gap-4">
          <motion.button
            onClick={() => {
              handleDayDataUpdate('hours', {
                ...hours,
                isOpen: !isOpen,
                type: !isOpen ? 'specific' : undefined,
                timeRanges: !isOpen ? [{ start: '09:00', end: '17:00' }] : undefined
              });
            }}
            className={cn(
              "flex items-center gap-2 px-4 py-2 rounded-md text-sm font-medium transition-all duration-200",
              isOpen 
                ? "bg-gray-900 text-white hover:bg-gray-800 shadow-sm hover:shadow"
                : "bg-gray-100 text-gray-500 hover:bg-gray-200"
            )}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            {isOpen ? 'Open' : 'Closed'}
          </motion.button>

          {isOpen && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <motion.button
                    onClick={() => {
                      handleDayDataUpdate('hours', {
                        ...hours,
                        isOpen: true,
                        type: isAllDay ? 'specific' : 'allDay',
                        timeRanges: isAllDay ? [{ start: '09:00', end: '17:00' }] : undefined
                      });
                    }}
                    className={cn(
                      "inline-flex items-center gap-2 h-9 px-3 rounded-md transition-all duration-200",
                      isAllDay 
                        ? "bg-gray-900 text-white hover:bg-gray-800 shadow-sm hover:shadow" 
                        : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                    )}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <Clock className="w-4 h-4" />
                    <span className="text-sm font-medium">
                      {isAllDay ? 'Open All Day' : 'Custom Hours'}
                    </span>
                  </motion.button>
                </TooltipTrigger>
                <TooltipContent 
                  side="bottom"
                  align="left"
                  className="z-[9999]"
                >
                  Click to switch between open all day and specific opening times.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>

        <AnimatePresence mode="wait">
          {isOpen && !isAllDay && (
            <motion.div 
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{
                height: {
                  duration: 0.3,
                  ease: [0.16, 1, 0.3, 1]
                }
              }}
              className="flex items-center gap-4"
            >
              <div className="flex-1 space-y-2">
                <label className="text-sm text-gray-600">Opening Time</label>
                <Select
                  value={timeRange.start}
                  onValueChange={(value) => {
                    handleDayDataUpdate('hours', {
                      ...hours,
                      type: 'specific',
                      isOpen: true,
                      timeRanges: [{ ...timeRange, start: value }]
                    });
                  }}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue>
                      {(value) => formatTimeDisplay(value)}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent 
                    className="max-h-[300px] overflow-y-auto no-scrollbar"
                    style={{
                      scrollbarWidth: 'none',
                      msOverflowStyle: 'none'
                    }}
                  >
                    {TIME_OPTIONS.map((time) => (
                      <SelectItem key={time.value} value={time.value}>
                        {time.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="flex items-center self-end pb-2">
                <span className="text-sm font-medium text-gray-400">to</span>
              </div>

              <div className="flex-1 space-y-2">
                <label className="text-sm text-gray-600">Closing Time</label>
                <Select
                  value={timeRange.end}
                  onValueChange={(value) => {
                    handleDayDataUpdate('hours', {
                      ...hours,
                      type: 'specific',
                      isOpen: true,
                      timeRanges: [{ ...timeRange, end: value }]
                    });
                  }}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue>
                      {(value) => formatTimeDisplay(value)}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent 
                    className="max-h-[300px] overflow-y-auto no-scrollbar"
                    style={{
                      scrollbarWidth: 'none',
                      msOverflowStyle: 'none'
                    }}
                  >
                    {TIME_OPTIONS.map((time) => (
                      <SelectItem key={time.value} value={time.value}>
                        {time.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    );
  };

  const renderLastLoadContent = () => {
    const todayData = dayData || {};
    const lastLoad = todayData.lastLoad || {
      possible: true,
      unified: false,
      unified_time: null,
      wash_time: null,
      dry_time: null
    };

    // Check if last load settings are possible for this day
    if (!canHaveLastLoad(todayData)) {
      return (
        <motion.div 
          className="space-y-6"
          initial={{ opacity: 0, y: 4 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
            <p className="text-sm text-gray-600">
              {!todayData.hours?.isOpen 
                ? 'Last load settings not available for closed days' 
                : todayData.hours?.type === 'allDay'
                  ? 'Last load settings not available for 24/7 operation'
                  : 'Configure business hours first to set last load times'
              }
            </p>
          </div>
        </motion.div>
      );
    }

    

    return (
      <motion.div className="space-y-6">
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <Timer className="w-5 h-5 text-gray-400" />
            <span className="text-sm font-medium text-gray-700">Same Time for Wash and Dry</span>
          </div>
          <Switch
            checked={lastLoad.unified}
            onCheckedChange={(checked) => {
              handleDayDataUpdate('lastLoad', {
                ...lastLoad,
                unified: checked,
                unified_time: checked ? (lastLoad.wash_time || null) : null,
                wash_time: !checked ? (lastLoad.unified_time || null) : null,
                dry_time: !checked ? (lastLoad.unified_time || null) : null
              });
            }}
          />
        </div>

        <AnimatePresence mode="wait">
          {lastLoad.unified ? (
            <motion.div 
              key="unified"
              initial={{ opacity: 0, y: 4 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -4 }}
              className="space-y-2"
            >
              <label className="text-sm font-medium text-gray-700">Last Load Time</label>
              <Select
                value={lastLoad.unified_time || ''}
                onValueChange={(value) => {
                  handleDayDataUpdate('lastLoad', {
                    ...lastLoad,
                    unified_time: value
                  });
                }}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select time">
                    {(value) => value ? formatTimeDisplay(value) : "Select time"}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent className="max-h-[300px]">
                  {TIME_OPTIONS.map((time) => (
                    <SelectItem key={time.value} value={time.value}>
                      {time.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </motion.div>
          ) : (
            <motion.div 
              key="split" 
              initial={{ opacity: 0, y: 4 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -4 }}
              className="space-y-6"
            >
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">Last Wash Time</label>
                <Select
                  value={lastLoad.wash_time || ''}
                  onValueChange={(value) => {
                    handleDayDataUpdate('lastLoad', {
                      ...lastLoad,
                      wash_time: value
                    });
                  }}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select time">
                      {(value) => value ? formatTimeDisplay(value) : "Select time"}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent className="max-h-[300px]">
                    {TIME_OPTIONS.map((time) => (
                      <SelectItem key={time.value} value={time.value}>
                        {time.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">Last Dry Time</label>
                <Select
                  value={lastLoad.dry_time || ''}
                  onValueChange={(value) => {
                    handleDayDataUpdate('lastLoad', {
                      ...lastLoad,
                      dry_time: value
                    });
                  }}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select time">
                      {(value) => value ? formatTimeDisplay(value) : "Select time"}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent className="max-h-[300px]">
                    {TIME_OPTIONS.map((time) => (
                      <SelectItem key={time.value} value={time.value}>
                        {time.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    );
  };

  // Update renderAttendantContent
  const renderAttendantContent = () => {
    const todayData = dayData || {};
    const attendant = dayData.attendant || {
      isPresent: false,
      timeRange: { start: '09:00', end: '17:00' }
    };

    // Add check for closed days
    if (!canHaveAttendants(todayData)) {
      return (
        <motion.div 
          className="space-y-6"
          initial={{ opacity: 0, y: 4 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
            <p className="text-sm text-gray-600">
              {!todayData.hours?.isOpen 
                ? 'Attendant settings not available for closed days'
                : 'Configure business hours first to set attendant schedule'
              }
            </p>
          </div>
        </motion.div>
      );
    }

    return (
      <motion.div 
        className="space-y-6"
        initial={{ opacity: 0, y: 4 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.3,
          ease: [0.16, 1, 0.3, 1]
        }}
      >
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <Users className="w-5 h-5 text-gray-400" />
            <span className="text-sm font-medium text-gray-700">Attendant Present</span>
          </div>
          <Switch
            checked={attendant.isPresent}
            onCheckedChange={(checked) => {
              handleDayDataUpdate('attendant', {
                isPresent: checked,
                // Only include timeRange if attendants are present
                ...(checked ? { timeRange: attendant.timeRange || { start: '09:00', end: '17:00' } } : {})
              });
            }}
          />
        </div>

        <AnimatePresence mode="wait">
          {attendant.isPresent && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="grid grid-cols-2 gap-4"
            >
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">Start Time</label>
                <Select
                  value={attendant.timeRange?.start || '09:00'}
                  onValueChange={(value) => {
                    handleDayDataUpdate('attendant', {
                      ...attendant,
                      timeRange: {
                        ...attendant.timeRange,
                        start: value
                      }
                    });
                  }}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue>
                      {(value) => formatTimeDisplay(value)}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent className="max-h-[300px] overflow-y-auto no-scrollbar">
                    {TIME_OPTIONS.map((time) => (
                      <SelectItem key={time.value} value={time.value}>
                        {time.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">End Time</label>
                <Select
                  value={attendant.timeRange?.end || '17:00'}
                  onValueChange={(value) => {
                    handleDayDataUpdate('attendant', {
                      ...attendant,
                      timeRange: {
                        ...attendant.timeRange,
                        end: value
                      }
                    });
                  }}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue>
                      {(value) => formatTimeDisplay(value)}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent className="max-h-[300px] overflow-y-auto no-scrollbar">
                    {TIME_OPTIONS.map((time) => (
                      <SelectItem key={time.value} value={time.value}>
                        {time.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    );
  };

  const renderSecurityContent = () => {
    const todayData = dayData || {};
    const security = dayData.security || {
      autoLock: false,
      lockTime: '22:00',
      details: ''
    };

    // Add check for closed days and 24/7
    if (!canHaveSecurity(todayData)) {
      return (
        <motion.div 
          className="space-y-6"
          initial={{ opacity: 0, y: 4 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
            <p className="text-sm text-gray-600">
              {!todayData.hours?.isOpen 
                ? 'Security settings not available for closed days'
                : todayData.hours?.type === 'allDay'
                  ? 'Security settings not available for 24/7 operation'
                  : 'Configure business hours first to set security settings'
              }
            </p>
          </div>
        </motion.div>
      );
    }

    return (
      <motion.div 
        className="space-y-6"
        initial={{ opacity: 0, y: 4 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.3,
          ease: [0.16, 1, 0.3, 1]
        }}
      >
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <Shield className="w-5 h-5 text-gray-400" />
            <span className="text-sm font-medium text-gray-700">Doors Lock When Closed</span>
          </div>
          <Switch
            checked={security.autoLock}
            onCheckedChange={(checked) => {
              handleDayDataUpdate('security', {
                ...security,
                autoLock: checked
              });
            }}
          />
        </div>

        <AnimatePresence mode="wait">
          {security.autoLock && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="space-y-6"
            >
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">Lock Time</label>
                <Select
                  value={security.lockTime}
                  onValueChange={(value) => {
                    handleDayDataUpdate('security', {
                      ...security,
                      lockTime: value
                    });
                  }}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue>
                      {(value) => formatTimeDisplay(value)}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent className="max-h-[300px] overflow-y-auto no-scrollbar">
                    {TIME_OPTIONS.map((time) => (
                      <SelectItem key={time.value} value={time.value}>
                        {time.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">How Doors Lock</label>
                <motion.textarea
                  value={security.details || ''}
                  onChange={(e) => {
                    handleDayDataUpdate('security', {
                      ...security,
                      details: e.target.value
                    });
                  }}
                  placeholder="Do you lock automatically or by hand? Any other info..."
                  className="w-full h-24 px-3 py-2 text-sm bg-gray-50 border border-gray-200 rounded-lg focus:ring-1 focus:ring-gray-200 resize-none transition-colors duration-200 placeholder:text-gray-400"
                  initial={false}
                  animate={{
                    borderColor: security.details ? 'rgb(17, 24, 39)' : 'rgb(229, 231, 235)'
                  }}
                  whileFocus={{
                    borderColor: 'rgb(17, 24, 39)',
                    backgroundColor: 'white'
                  }}
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    );
  };

  const renderMachineCard = (machine, index) => {
    const isWasher = activeMachineType === 'washers';
    const machineNumber = index + 1;
    const machineInputKey = `${machine.id}_cost`;
    const minutesInputKey = `${machine.id}_minutes`;

    return (
      <motion.div 
        key={machine.id}
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        className="p-4 bg-gray-50 rounded-lg border border-gray-200"
      >
        <div className="flex items-center gap-6">
          <div className="flex items-center gap-3">
            <h4 className="text-sm font-medium text-gray-900">
              {isWasher ? `Washer #${machineNumber}` : `Dryer #${machineNumber}`}
            </h4>
            <button
              onClick={() => handleDeleteMachine(index)}
              className="p-1 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-full transition-colors"
            >
              <Trash2 className="w-4 h-4" />
            </button>
          </div>

          {isWasher && (
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-600">Load Type</span>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => handleUpdateMachine(index, { ...machine, loadType: 'front' })}
                  className={cn(
                    "px-3 py-1.5 text-sm font-medium rounded-md transition-colors",
                    machine.loadType === 'front'
                      ? "bg-gray-900 text-white"
                      : "bg-white border border-gray-200 text-gray-600 hover:bg-gray-50"
                  )}
                >
                  Front
                </button>
                <button
                  onClick={() => handleUpdateMachine(index, { ...machine, loadType: 'top' })}
                  className={cn(
                    "px-3 py-1.5 text-sm font-medium rounded-md transition-colors",
                    machine.loadType === 'top'
                      ? "bg-gray-900 text-white"
                      : "bg-white border border-gray-200 text-gray-600 hover:bg-gray-50"
                  )}
                >
                  Top
                </button>
              </div>
            </div>
          )}

          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-600">Capacity</span>
            <Select
              value={machine.capacity.toString()}
              onValueChange={(value) => handleUpdateMachine(index, { 
                ...machine, 
                capacity: parseInt(value) 
              })}
            >
              <SelectTrigger className="w-32">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {CAPACITY_OPTIONS.map((option) => (
                  <SelectItem key={option.value} value={option.value.toString()}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-600">Cost</span>
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-400">$</span>
              <input
                type="number"
                value={rawInputValues.machines[machineInputKey] ?? machine.cost?.toString()}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setRawInputValues(prev => ({
                    ...prev,
                    machines: {
                      ...prev.machines,
                      [machineInputKey]: newValue
                    }
                  }));
                  handleUpdateMachine(index, { 
                    ...machine, 
                    cost: newValue
                  });
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (value === '' || isNaN(parseFloat(value))) {
                    const newValue = '0';
                    setRawInputValues(prev => ({
                      ...prev,
                      machines: {
                        ...prev.machines,
                        [machineInputKey]: newValue
                      }
                    }));
                    handleUpdateMachine(index, { 
                      ...machine, 
                      cost: 0
                    });
                  }
                }}
                className="w-24 px-3 py-1.5 bg-white border border-gray-200 rounded-md text-sm text-gray-900 focus:outline-none focus:ring-1 focus:ring-gray-200"
                min="0"
                step="0.01"
              />
            </div>
          </div>

          {!isWasher && (
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-600">Minutes</span>
              <input
                type="number"
                value={rawInputValues.machines[minutesInputKey] ?? machine.minutes?.toString()}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setRawInputValues(prev => ({
                    ...prev,
                    machines: {
                      ...prev.machines,
                      [minutesInputKey]: newValue
                    }
                  }));
                  handleUpdateMachine(index, { 
                    ...machine, 
                    minutes: newValue
                  });
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (value === '' || isNaN(parseInt(value))) {
                    const newValue = '1';
                    setRawInputValues(prev => ({
                      ...prev,
                      machines: {
                        ...prev.machines,
                        [minutesInputKey]: newValue
                      }
                    }));
                    handleUpdateMachine(index, { 
                      ...machine, 
                      minutes: 1
                    });
                  }
                }}
                className="w-24 px-3 py-1.5 bg-white border border-gray-200 rounded-md text-sm text-gray-900 focus:outline-none focus:ring-1 focus:ring-gray-200"
                min="1"
              />
            </div>
          )}
        </div>
      </motion.div>
    );
  };

  const renderMachinesContent = () => (
    <>
      {/* Machine type selector */}
      <div className="flex items-center gap-3 mb-6">
        <TabButton
          active={activeMachineType === 'washers'}
          icon={Settings}
          label="Washers"
          onClick={() => setActiveMachineType('washers')}
        />
        <TabButton
          active={activeMachineType === 'dryers'}
          icon={Wind}
          label="Dryers"
          onClick={() => setActiveMachineType('dryers')}
        />
      </div>

      {/* Machine list */}
      <div className="space-y-4">
        {(machines[activeMachineType] || []).map((machine, index) => 
          renderMachineCard(machine, index)
        )}

        {machines[activeMachineType].length === 0 && (
          <div className="p-8 text-center bg-gray-50 rounded-lg border border-gray-200">
            <p className="text-sm text-gray-500">
              No {activeMachineType === 'washers' ? 'washers' : 'dryers'} configured yet. 
              Click the button above to add one.
            </p>
          </div>
        )}
      </div>

      {/* Add machine button */}
      <div className="mt-4 flex justify-end">
        <motion.button
          onClick={handleAddMachine}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="flex items-center gap-2 px-4 py-2 bg-gray-900 text-white rounded-md hover:bg-gray-800"
        >
          <Plus className="w-4 h-4" />
          <span className="text-sm">
            Add {activeMachineType === 'washers' ? 'Washer' : 'Dryer'}
          </span>
        </motion.button>
      </div>
    </>
  );

  const renderPaymentContent = () => (
    <>
      <div className="grid grid-cols-4 gap-4 relative pb-4">
        {/* Cash/Coin */}
        <motion.div
          variants={serviceCardVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          onClick={() => setActivePaymentType('cash')}
          className={`relative p-3 rounded-lg border transition-colors duration-200 cursor-pointer ${
            activePaymentType === 'cash' 
              ? 'bg-gray-50' 
              : paymentMethods.cash.active 
                ? 'border-gray-300 hover:border-gray-400 hover:bg-gray-50'
                : 'border-gray-200 hover:border-gray-300 bg-gray-50/50'
          }`}
        >
          <div className="flex items-center justify-between gap-3 h-6">
            <div className="flex items-center gap-2 min-w-0">
              <Wallet className={`w-4 h-4 flex-shrink-0 transition-colors duration-200 ${
                paymentMethods.cash.active 
                  ? activePaymentType === 'cash'
                    ? 'text-gray-900'
                    : 'text-gray-500'
                  : 'text-gray-300'
              }`} />
              <span className={`text-sm font-medium truncate transition-colors duration-200 ${
                paymentMethods.cash.active 
                  ? activePaymentType === 'cash'
                    ? 'text-gray-900'
                    : 'text-gray-600'
                  : 'text-gray-400'
              }`}>
                Cash/Coin
              </span>
            </div>
            <div onClick={e => e.stopPropagation()} className="flex-shrink-0 transition-opacity duration-200">
              <Switch
                checked={paymentMethods.cash.active}
                onCheckedChange={(checked) => handleUpdatePaymentMethod('cash', { active: checked })}
                className={paymentMethods.cash.active ? "" : "opacity-50"}
              />
            </div>
          </div>

          <AnimatePresence>
            {activePaymentType === 'cash' && (
              <motion.div 
                layoutId="payment-selection-indicator"
                variants={selectionIndicatorVariants}
                initial="initial"
                animate="animate"
                className="absolute inset-0 rounded-lg border-2 border-gray-900 pointer-events-none"
              />
            )}
          </AnimatePresence>
        </motion.div>

        {/* Card */}
        <motion.div
          variants={serviceCardVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          onClick={() => setActivePaymentType('card')}
          className={`relative p-3 rounded-lg border transition-colors duration-200 cursor-pointer ${
            activePaymentType === 'card' 
              ? 'bg-gray-50' 
              : paymentMethods.card.active 
                ? 'border-gray-300 hover:border-gray-400 hover:bg-gray-50'
                : 'border-gray-200 hover:border-gray-300 bg-gray-50/50'
          }`}
        >
          <div className="flex items-center justify-between gap-3 h-6">
            <div className="flex items-center gap-2 min-w-0">
              <CreditCard className={`w-4 h-4 flex-shrink-0 transition-colors duration-200 ${
                paymentMethods.card.active 
                  ? activePaymentType === 'card'
                    ? 'text-gray-900'
                    : 'text-gray-500'
                  : 'text-gray-300'
              }`} />
              <span className={`text-sm font-medium truncate transition-colors duration-200 ${
                paymentMethods.card.active 
                  ? activePaymentType === 'card'
                    ? 'text-gray-900'
                    : 'text-gray-600'
                  : 'text-gray-400'
              }`}>
                Card
              </span>
            </div>
            <div onClick={e => e.stopPropagation()} className="flex-shrink-0 transition-opacity duration-200">
              <Switch
                checked={paymentMethods.card.active}
                onCheckedChange={(checked) => handleUpdatePaymentMethod('card', { active: checked })}
                className={paymentMethods.card.active ? "" : "opacity-50"}
              />
            </div>
          </div>

          <AnimatePresence>
            {activePaymentType === 'card' && (
              <motion.div 
                layoutId="payment-selection-indicator"
                variants={selectionIndicatorVariants}
                initial="initial"
                animate="animate"
                className="absolute inset-0 rounded-lg border-2 border-gray-900 pointer-events-none"
              />
            )}
          </AnimatePresence>
        </motion.div>

        {/* Laundry Card */}
        <motion.div
          variants={serviceCardVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          onClick={() => setActivePaymentType('laundryCard')}
          className={`relative p-3 rounded-lg border transition-colors duration-200 cursor-pointer ${
            activePaymentType === 'laundryCard' 
              ? 'bg-gray-50' 
              : paymentMethods.laundryCard.active 
                ? 'border-gray-300 hover:border-gray-400 hover:bg-gray-50'
                : 'border-gray-200 hover:border-gray-300 bg-gray-50/50'
          }`}
        >
          <div className="flex items-center justify-between gap-3 h-6">
            <div className="flex items-center gap-2 min-w-0">
              <Receipt className={`w-4 h-4 flex-shrink-0 transition-colors duration-200 ${
                paymentMethods.laundryCard.active 
                  ? activePaymentType === 'laundryCard'
                    ? 'text-gray-900'
                    : 'text-gray-500'
                  : 'text-gray-300'
              }`} />
              <span className={`text-sm font-medium truncate transition-colors duration-200 ${
                paymentMethods.laundryCard.active 
                  ? activePaymentType === 'laundryCard'
                    ? 'text-gray-900'
                    : 'text-gray-600'
                  : 'text-gray-400'
              }`}>
                Rewards
              </span>
            </div>
            <div onClick={e => e.stopPropagation()} className="flex-shrink-0 transition-opacity duration-200">
              <Switch
                checked={paymentMethods.laundryCard.active}
                onCheckedChange={(checked) => handleUpdatePaymentMethod('laundryCard', { active: checked })}
                className={paymentMethods.laundryCard.active ? "" : "opacity-50"}
              />
            </div>
          </div>

          <AnimatePresence>
            {activePaymentType === 'laundryCard' && (
              <motion.div 
                layoutId="payment-selection-indicator"
                variants={selectionIndicatorVariants}
                initial="initial"
                animate="animate"
                className="absolute inset-0 rounded-lg border-2 border-gray-900 pointer-events-none"
              />
            )}
          </AnimatePresence>
        </motion.div>

        {/* Crypto */}
        <motion.div
          variants={serviceCardVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          onClick={() => setActivePaymentType('crypto')}
          className={`relative p-3 rounded-lg border transition-colors duration-200 cursor-pointer ${
            activePaymentType === 'crypto' 
              ? 'bg-gray-50' 
              : paymentMethods.crypto.active 
                ? 'border-gray-300 hover:border-gray-400 hover:bg-gray-50'
                : 'border-gray-200 hover:border-gray-300 bg-gray-50/50'
          }`}
        >
          <div className="flex items-center justify-between gap-3 h-6">
            <div className="flex items-center gap-2 min-w-0">
              <Bitcoin className={`w-4 h-4 flex-shrink-0 transition-colors duration-200 ${
                paymentMethods.crypto.active 
                  ? activePaymentType === 'crypto'
                    ? 'text-gray-900'
                    : 'text-gray-500'
                  : 'text-gray-300'
              }`} />
              <span className={`text-sm font-medium truncate transition-colors duration-200 ${
                paymentMethods.crypto.active 
                  ? activePaymentType === 'crypto'
                    ? 'text-gray-900'
                    : 'text-gray-600'
                  : 'text-gray-400'
              }`}>
                Crypto
              </span>
            </div>
            <div onClick={e => e.stopPropagation()} className="flex-shrink-0 transition-opacity duration-200">
              <Switch
                checked={paymentMethods.crypto.active}
                onCheckedChange={(checked) => handleUpdatePaymentMethod('crypto', { active: checked })}
                className={paymentMethods.crypto.active ? "" : "opacity-50"}
              />
            </div>
          </div>

          <AnimatePresence>
            {activePaymentType === 'crypto' && (
              <motion.div 
                layoutId="payment-selection-indicator"
                variants={selectionIndicatorVariants}
                initial="initial"
                animate="animate"
                className="absolute inset-0 rounded-lg border-2 border-gray-900 pointer-events-none"
              />
            )}
          </AnimatePresence>
        </motion.div>
      </div>

      {/* Content Area */}
      <AnimatePresence mode="wait">
        {!paymentMethods[activePaymentType]?.active ? (
          <motion.div
            variants={disabledMessageVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className="p-4 bg-gray-50 rounded-lg border border-gray-200"
          >
            <p className="text-sm text-gray-500 text-center">
              Enable this payment method to configure its settings
            </p>
          </motion.div>
        ) : (
          <motion.div
            key={activePaymentType}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{
              duration: 0.2,
              ease: [0.16, 1, 0.3, 1]
            }}
            className="p-4 bg-gray-50 rounded-lg border border-gray-200 space-y-4"
          >
            {activePaymentType === 'cash' && (
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-700">ATM Available On-Site</span>
                <Switch
                  checked={paymentMethods.cash.atmOnSite}
                  onCheckedChange={(checked) => 
                    handleUpdatePaymentMethod('cash', { atmOnSite: checked })
                  }
                />
              </div>
            )}

            {activePaymentType === 'card' && (
              <>
                <div className="flex items-center gap-6">
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-gray-700">Accept Credit</span>
                    <Switch
                      checked={paymentMethods.card.acceptedCards.credit}
                      onCheckedChange={(checked) => 
                        handleUpdatePaymentMethod('card', { 
                          acceptedCards: { 
                            ...paymentMethods.card.acceptedCards, 
                            credit: checked 
                          } 
                        })
                      }
                    />
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-gray-700">Accept Debit</span>
                    <Switch
                      checked={paymentMethods.card.acceptedCards.debit}
                      onCheckedChange={(checked) => 
                        handleUpdatePaymentMethod('card', { 
                          acceptedCards: { 
                            ...paymentMethods.card.acceptedCards, 
                            debit: checked 
                          } 
                        })
                      }
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <label className="text-sm text-gray-700">Hold Information</label>
                  <textarea
                    value={paymentMethods.card.holdInfo}
                    onChange={(e) => 
                      handleUpdatePaymentMethod('card', { holdInfo: e.target.value })
                    }
                    placeholder="Enter information about card holds..."
                    className="w-full h-24 px-3 py-2 text-sm bg-white border border-gray-200 rounded-lg focus:ring-1 focus:ring-gray-200 resize-none"
                  />
                </div>
              </>
            )}

            {activePaymentType === 'laundryCard' && (
              <>
                <div className="flex items-center gap-2">
                  <span className="text-sm text-gray-700">Card Cost</span>
                  <div className="flex items-center gap-1">
                    <span className="text-sm text-gray-400">$</span>
                    <input
                      type="number"
                      value={rawInputValues.laundryCardCost}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRawInputValues(prev => ({
                          ...prev,
                          laundryCardCost: newValue
                        }));
                        handleUpdatePaymentMethod('laundryCard', { cost: newValue })
                      }}
                      onBlur={(e) => {
                        const value = e.target.value;
                        if (value === '' || isNaN(parseFloat(value))) {
                          const newValue = '0';
                          setRawInputValues(prev => ({
                            ...prev,
                            laundryCardCost: newValue
                          }));
                          handleUpdatePaymentMethod('laundryCard', { cost: 0 })
                        }
                      }}
                      className="w-24 px-3 py-1.5 bg-white border border-gray-200 rounded-md text-sm"
                      min="0"
                      step="0.01"
                    />
                  </div>
                </div>

                {[
                  { label: 'How to Get Card', field: 'howToGet' },
                  { label: 'How It Works', field: 'howItWorks' },
                  { label: 'Rewards Program', field: 'rewards' }
                ].map(({ label, field }) => (
                  <div key={field} className="space-y-2">
                    <label className="text-sm text-gray-700">{label}</label>
                    <textarea
                      value={paymentMethods.laundryCard[field]}
                      onChange={(e) => 
                        handleUpdatePaymentMethod('laundryCard', { [field]: e.target.value })
                      }
                      className="w-full h-24 px-3 py-2 text-sm bg-white border border-gray-200 rounded-lg focus:ring-1 focus:ring-gray-200 resize-none"
                    />
                  </div>
                ))}
              </>
            )}

            {activePaymentType === 'crypto' && (
              <div className="grid grid-cols-2 gap-4">
                {Object.entries(paymentMethods.crypto.acceptedCoins).map(([coin, accepted]) => (
                  <div key={coin} className="flex items-center justify-between">
                    <span className="text-sm text-gray-700">{coin}</span>
                    <Switch
                      checked={accepted}
                      onCheckedChange={(checked) => 
                        handleUpdatePaymentMethod('crypto', {
                          acceptedCoins: {
                            ...paymentMethods.crypto.acceptedCoins,
                            [coin]: checked
                          }
                        })
                      }
                    />
                  </div>
                ))}
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );

  const renderOtherInfoContent = () => {
    return (
      <div className="space-y-6">
        {/* Sub-tabs for Other Info */}
        <div className="flex items-center gap-3">
          <TabButton
            active={otherInfoTab === 'additional'}
            icon={Info}
            label="Additional Info"
            onClick={() => setOtherInfoTab('additional')}
          />
          <TabButton
            active={otherInfoTab === 'faqs'}
            icon={HelpCircle}
            label="FAQs"
            onClick={() => setOtherInfoTab('faqs')}
          />
        </div>

        {/* Content based on selected sub-tab */}
        <AnimatePresence mode="wait">
          <motion.div
            key={otherInfoTab}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {otherInfoTab === 'additional' && (
              <OtherInfoPanel
                value={selectedLocation?.services?.selfServe?.otherInfo || []}
                onChange={(newValue) => {
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      selfServe: {
                        ...selectedLocation.services.selfServe,
                        otherInfo: newValue
                      }
                    }
                  });
                }}
                title="Additional Self Service Information"
              />
            )}

            {otherInfoTab === 'faqs' && (
              <FAQPanel
                faqs={selectedLocation?.services?.selfServe?.faqs || []}
                onChange={(newFaqs) => {
                  onUpdateLocation({
                    ...selectedLocation,
                    services: {
                      ...selectedLocation.services,
                      selfServe: {
                        ...selectedLocation.services.selfServe,
                        faqs: newFaqs
                      }
                    }
                  });
                }}
                title="Self Service FAQs"
              />
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    );
  };

  const handleUpdateHolidayHours = (updatedHours) => {
    onUpdateLocation({
      ...selectedLocation,
      services: {
        ...selectedLocation.services,
        selfServe: {
          ...selectedLocation.services.selfServe,
          holidayHours: updatedHours
        }
      }
    });
  };

  return (
    <div className="space-y-6">
      <HolidayHoursModal 
        isOpen={showHolidayModal}
        onClose={() => setShowHolidayModal(false)}
        holidayHours={selectedLocation?.services?.selfServe?.holidayHours || {}}
        onUpdateHolidayHours={handleUpdateHolidayHours}
      />
      {/* Main Navigation Tabs */}
      <div className="flex items-center gap-2 flex-wrap md:flex-nowrap overflow-x-auto pb-2 -mx-2 px-2">
        <TabButton
          active={activeTab === 'hours'}
          icon={Clock}
          label={window.innerWidth < 768 ? '' : "Hours"}
          onClick={() => setActiveTab('hours')}
        />
        <TabButton
          active={activeTab === 'lastLoad'}
          icon={Timer}
          label="Last Load"
          onClick={() => setActiveTab('lastLoad')}
        />
        <TabButton
          active={activeTab === 'attendant'}
          icon={Users}
          label="Attendant"
          onClick={() => setActiveTab('attendant')}
        />
        <TabButton
          active={activeTab === 'security'}
          icon={Shield}
          label="Security"
          onClick={() => setActiveTab('security')}
        />
        <TabButton
          active={activeTab === 'machines'}
          icon={Wrench}
          label="Machines"
          onClick={() => setActiveTab('machines')}
        />
        <TabButton
          active={activeTab === 'payment'}
          icon={Wallet}
          label="Payment"
          onClick={() => setActiveTab('payment')}
        />
        <TabButton
          active={activeTab === 'otherInfo'}
          icon={Info}
          label="Other Info"
          onClick={() => setActiveTab('otherInfo')}
        />
      </div>

      {/* Day Selector - Only show for relevant tabs */}
      {showDaySelector && (
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <button
              onClick={() => setCurrentDayIndex((prev) => (prev - 1 + 7) % 7)}
              className="p-2 hover:bg-gray-100 rounded-md transition-colors"
            >
              <ChevronLeft className="w-5 h-5 text-gray-600" />
            </button>
            <h3 className="text-lg font-medium text-gray-900">{currentDay}</h3>
            <button
              onClick={() => setCurrentDayIndex((prev) => (prev + 1) % 7)}
              className="p-2 hover:bg-gray-100 rounded-md transition-colors"
            >
              <ChevronRight className="w-5 h-5 text-gray-600" />
            </button>
          </div>

          <motion.button
            onClick={() => setShowApplyAllModal(true)}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="flex items-center gap-2 px-3 py-1.5 text-sm text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-md transition-colors"
          >
            <Copy className="w-4 h-4" />
            <span>Apply to All Days</span>
          </motion.button>
        </div>
      )}

      {/* Content Area */}
      <div className="bg-white rounded-lg border border-gray-200 p-6">
        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{
              duration: 0.2,
              ease: [0.16, 1, 0.3, 1]
            }}
          >
            {activeTab === 'hours' && renderHoursContent()}
            {activeTab === 'lastLoad' && renderLastLoadContent()}
            {activeTab === 'attendant' && renderAttendantContent()}
            {activeTab === 'security' && renderSecurityContent()}
            {activeTab === 'machines' && renderMachinesContent()}
            {activeTab === 'payment' && renderPaymentContent()}
            {activeTab === 'otherInfo' && renderOtherInfoContent()}
          </motion.div>
        </AnimatePresence>
      </div>

      {/* Apply to All Days Confirmation Modal */}
      <ConfirmModal
        isOpen={showApplyAllModal}
        onClose={() => setShowApplyAllModal(false)}
        onConfirm={handleApplyToAllDays}
        title="Apply to All Days"
        message={`Are you sure you want to apply ${currentDay}'s settings to all other days? This will override any existing settings.`}
        confirmText="Apply to All"
        confirmStyle="bg-gray-900 hover:bg-gray-800"
      />
    </div>
  );
  };

  export default SelfServicePanel;