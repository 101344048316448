import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Table } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/select";
import { Switch } from "../../ui/switch";
import { Button } from "../../ui/button";

const CSVMappingModal = ({
  isOpen,
  onClose,
  csvData,
  onConfirm,
  showPerField = false
}) => {
  const [hasHeaders, setHasHeaders] = useState(true);
  const [mapping, setMapping] = useState({
    name: '',
    price: '',
    per: ''
  });

  // Get the headers (first row)
  const headers = csvData?.[0] || {};
  // Get preview data from second row if headers enabled, first row if not
  const previewRow = hasHeaders ? csvData?.[1] : csvData?.[0];

  // Get column options from the first row
  const columnOptions = csvData?.[0] ? Object.entries(csvData[0]).map(([key, value]) => ({
    value: key,
    label: value ? value.trim() : ''
  })) : [];

  // Reset mapping when modal opens
  useEffect(() => {
    if (isOpen) {
      setHasHeaders(true);
      setMapping({
        name: '',
        price: '',
        per: ''
      });
    }
  }, [isOpen]);

  const handleConfirm = () => {
    // Skip first row if hasHeaders is true
    const dataToProcess = hasHeaders ? csvData.slice(1) : csvData;

    const cleanString = (str) => {
      if (!str) return '';
      // Remove quotes and commas from start and end, and trim whitespace
      return str.toString().replace(/^["',\s]+|["',\s]+$/g, '').trim();
    };

    const processPrice = (price) => {
      if (!price) return 0;
      // Remove $ and clean the string
      const cleanPrice = price.toString().replace(/[$,]/g, '').trim();
      // Convert to float and round to 2 decimal places
      return parseFloat(parseFloat(cleanPrice).toFixed(2)) || 0;
    };

    const processedData = dataToProcess.map(row => ({
      name: cleanPreviewValue(row[mapping.name]) || '',
      price: processPrice(row[mapping.price]),
      per: showPerField ? cleanPreviewValue(row[mapping.per]) || 'order' : undefined
    }));

    onConfirm(processedData);
    onClose();
  };

  const isValid = mapping.name && mapping.price && (!showPerField || mapping.per);

  // Function to clean preview value
  const cleanPreviewValue = (value) => {
    if (!value) return '';
    const cleaned = value.toString()
      .replace(/['"]+/g, '')  // Remove quotes
      .trim();
    return cleaned;
  };

  // Process price for preview display
  const formatPreviewPrice = (price) => {
    if (!price) return '';
    // Remove any existing $ and clean the string
    const cleanPrice = price.toString().replace(/[$]/g, '').trim();
    // Return with single $
    return `$${cleanPrice}`;
  };

  // Function to get selected value label
  const getSelectedLabel = (key) => {
    const option = columnOptions.find(opt => opt.value === mapping[key]);
    return option ? cleanPreviewValue(option.label) : 'Select column';
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[750px]">
        <DialogHeader className="px-6 py-4 border-b border-gray-200">
          <DialogTitle className="flex items-center gap-2 text-gray-900 text-xl font-semibold">
            <Table className="w-5 h-5" />
            Map CSV Columns
          </DialogTitle>
        </DialogHeader>

        <div className="p-6 space-y-8">
          {/* Headers Toggle */}
          <div className="bg-gray-50 rounded-lg border border-gray-200 p-4">
            <div className="flex items-center justify-between">
              <div className="space-y-1">
                <h3 className="text-sm font-medium text-gray-900">First Row Contains Headers</h3>
                <p className="text-sm text-gray-500">
                  Toggle this if your CSV's first row contains column names
                </p>
              </div>
              <Switch
                checked={hasHeaders}
                onCheckedChange={setHasHeaders}
              />
            </div>
          </div>

          {/* Column Mapping */}
          <div className="space-y-6">
            <h3 className="text-sm font-medium text-gray-900">Map Your Columns</h3>

            <div className="space-y-4">
              {/* Item Name Mapping */}
              <div className="grid grid-cols-[200px_1fr] gap-4 items-center">
                <div className="text-sm text-gray-700">Item Name Column</div>
                <Select
                  value={mapping.name}
                  onValueChange={(value) => setMapping({ ...mapping, name: value })}
                >
                  <SelectTrigger className="w-[240px]">
                    <SelectValue>
                      {cleanPreviewValue(getSelectedLabel('name'))}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="">Select column</SelectItem>
                    {columnOptions.map((col) => (
                      <SelectItem key={col.value} value={col.value}>
                        {cleanPreviewValue(col.label)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              {/* Price Mapping */}
              <div className="grid grid-cols-[200px_1fr] gap-4 items-center">
                <div className="text-sm text-gray-700">Price Column</div>
                <Select
                  value={mapping.price}
                  onValueChange={(value) => setMapping({ ...mapping, price: value })}
                >
                  <SelectTrigger className="w-[240px]">
                    <SelectValue>
                      {cleanPreviewValue(getSelectedLabel('price'))}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="">Select column</SelectItem>
                    {columnOptions.map((col) => (
                      <SelectItem key={col.value} value={col.value}>
                        {cleanPreviewValue(col.label)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              {/* Per Field Mapping (if needed) */}
              {showPerField && (
                <div className="grid grid-cols-[200px_1fr] gap-4 items-center">
                  <div className="text-sm text-gray-700">Per Column (Optional)</div>
                  <Select
                    value={mapping.per}
                    onValueChange={(value) => setMapping({ ...mapping, per: value })}
                  >
                    <SelectTrigger className="w-[240px]">
                      <SelectValue>
                        {cleanPreviewValue(getSelectedLabel('per'))}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="">Select column</SelectItem>
                      {columnOptions.map((col) => (
                        <SelectItem key={col.value} value={col.value}>
                          {cleanPreviewValue(col.label)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              )}
            </div>
          </div>

          {/* Preview Section */}
          <div className="space-y-3">
            <h4 className="text-sm font-medium text-gray-900">Preview</h4>
            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
              <div className="text-sm text-gray-600 font-sans">
                {mapping.name && mapping.price && (
                  <span>
                    {cleanPreviewValue(previewRow[mapping.name])} = {formatPreviewPrice(cleanPreviewValue(previewRow[mapping.price]))}
                    {showPerField && mapping.per && ` per ${cleanPreviewValue(previewRow[mapping.per])}`}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Actions */}
        <div className="flex justify-end gap-3 px-6 py-4 border-t border-gray-200">
          <Button
            variant="outline"
            onClick={onClose}
            className="text-gray-700 bg-gray-100 hover:bg-gray-200"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            disabled={!isValid}
            className="bg-gray-900 text-white hover:bg-gray-800"
          >
            Import Data
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CSVMappingModal; 