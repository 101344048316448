import React, { useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Upload, X, Plus } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../ui/dialog";
import { Input } from "../../ui/input";
import { Switch } from "../../ui/switch";
import CSVMappingModal from './CSVMappingModal';

const modalVariants = {
  hidden: { 
    opacity: 0, 
    y: 10,
    scale: 0.98
  },
  visible: { 
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 0.4,
      ease: [0.16, 1, 0.3, 1],
      staggerChildren: 0.1
    }
  },
  exit: { 
    opacity: 0,
    y: -10,
    scale: 0.98,
    transition: {
      duration: 0.3,
      ease: [0.16, 1, 0.3, 1]
    }
  }
};

const itemVariants = {
  hidden: { 
    opacity: 0,
    x: -10
  },
  visible: { 
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
      ease: [0.16, 1, 0.3, 1]
    }
  },
  exit: { 
    opacity: 0,
    x: 10,
    transition: {
      duration: 0.2,
      ease: [0.16, 1, 0.3, 1]
    }
  }
};

const ItemPricingModal = ({
  isOpen,
  onClose,
  title,
  items,
  onSave,
  showPerField = false
}) => {
  const fileInputRef = useRef(null);
  const [localItems, setLocalItems] = useState(items.map(item => ({
    ...item,
    per: item.per || 'order',
    isPlusWeight: item.isPlusWeight || false  
  })));
  const [showMappingModal, setShowMappingModal] = useState(false);
  const [parsedCSVData, setParsedCSVData] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      const text = e.target.result;

      // First, split the text into lines manually to handle the headers
      const lines = text.split('\n').map(line => line.trim()).filter(line => line);
      const headerLine = lines[0];
      const dataLines = lines.slice(1);

      // Parse headers
      const headers = headerLine.split(',').map(h => h.trim());

      // Parse data rows
      const parsedData = dataLines.map(line => {
        const values = line.split(',').map(val => val.trim());
        // Create an object with index-based and header-based keys
        return values.reduce((obj, val, idx) => {
          obj[idx] = val;  // Numeric index for backward compatibility
          obj[headers[idx]] = val;  // Header-based key
          return obj;
        }, {});
      });

      // Add headers to the start of the data array
      const finalData = [
        headers.reduce((obj, header, idx) => {
          obj[idx] = header;
          return obj;
        }, {}),
        ...parsedData
      ];

      console.log('Processed CSV data:', finalData);
      setParsedCSVData(finalData);
      setShowMappingModal(true);
    };

    reader.onerror = () => {
      console.error('Error reading file');
      alert('Error reading file. Please try again.');
    };

    reader.readAsText(file);
    event.target.value = '';
  };

  const handleMappedData = (mappedData) => {
    setLocalItems(mappedData);
    setShowMappingModal(false);
  };

  const handleUpdateItem = (index, field, value) => {
    const newItems = [...localItems];
    newItems[index] = { 
      ...newItems[index], 
      [field]: field === 'price' ? parseFloat(value) || 0 : value 
    };
    setLocalItems(newItems);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[900px] overflow-hidden">
        <motion.div
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="h-full"
        >
          <DialogHeader className="px-6 py-4 border-b border-gray-200">
            <div className="flex items-center justify-between">
              <DialogTitle className="text-xl font-semibold text-gray-900">
                {title}
              </DialogTitle>
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => fileInputRef.current?.click()}
                className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors duration-200"
              >
                <Upload className="w-4 h-4 mr-2" />
                Import CSV
              </motion.button>
            </div>
          </DialogHeader>

          <div className="p-6 space-y-6">
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ 
                opacity: 1, 
                y: 0,
                transition: {
                  duration: 0.3,
                  delay: 0.1,
                  ease: [0.16, 1, 0.3, 1]
                }
              }}
            >
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setLocalItems([...localItems, { name: '', price: 0, per: 'order' }])}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg hover:bg-gray-800 transition-colors duration-200"
              >
                <Plus className="w-4 h-4 mr-2" />
                Add Item
              </motion.button>
            </motion.div>

            <div className="space-y-3 max-h-[400px] overflow-y-auto pr-2">
              <AnimatePresence mode="popLayout">
                {localItems.map((item, index) => (
                  <motion.div
                    key={index}
                    variants={itemVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    layout
                    className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg border border-gray-200 group hover:border-gray-300 hover:shadow-sm transition-all duration-200"
                  >
                    <Input
                      placeholder="Item name"
                      value={item.name}
                      onChange={(e) => {
                        const newItems = [...localItems];
                        newItems[index].name = e.target.value;
                        setLocalItems(newItems);
                      }}
                      className="flex-1"
                    />

                    <div className="flex items-center gap-2">
                      <span className="text-sm text-gray-500">$</span>
                      <Input
                        type="number"
                        value={item.price}
                        onChange={(e) => {
                          const newItems = [...localItems];
                          newItems[index].price = parseFloat(e.target.value) || 0;
                          setLocalItems(newItems);
                        }}
                        className="w-24"
                        min="0"
                        step="0.01"
                      />
                      <span className="text-sm text-gray-500">+Weight?</span>
                      <Switch 
                        checked={item.isPlusWeight || false}
                        onCheckedChange={(checked) => {
                          const newItems = [...localItems];
                          newItems[index].isPlusWeight = checked;
                          setLocalItems(newItems);
                        }}
                      />
                    </div>

                    <motion.button
                      onClick={() => {
                        const newItems = localItems.filter((_, i) => i !== index);
                        setLocalItems(newItems);
                      }}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      className="p-1.5 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-full opacity-0 group-hover:opacity-100 transition-all duration-200"
                    >
                      <X className="w-4 h-4" />
                    </motion.button>
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>

            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileUpload}
              accept=".csv"
              className="hidden"
            />
          </div>

          <motion.div 
            className="flex justify-end gap-3 px-6 py-4 border-t border-gray-200"
            initial={{ opacity: 0, y: 10 }}
            animate={{ 
              opacity: 1, 
              y: 0,
              transition: {
                delay: 0.2,
                duration: 0.3,
                ease: [0.16, 1, 0.3, 1]
              }
            }}
          >
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors duration-200"
            >
              Cancel
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => {
                onSave(localItems);
                onClose();
              }}
              className="px-4 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg hover:bg-gray-800 transition-colors duration-200"
            >
              Save Changes
            </motion.button>
          </motion.div>
        </motion.div>
      </DialogContent>

      {showMappingModal && parsedCSVData && (
        <CSVMappingModal
          isOpen={showMappingModal}
          onClose={() => {
            setShowMappingModal(false);
            setParsedCSVData(null);
          }}
          csvData={parsedCSVData}
          onConfirm={handleMappedData}
          showPerField={showPerField}
        />
      )}
    </Dialog>
  );
};

export default ItemPricingModal; 