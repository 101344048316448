import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Calendar,
  DollarSign,
  Hourglass,
  Plus,
  Trash2,
  Droplet,
  Sparkles,
  Timer,
  Info,
  HelpCircle,
  Settings,
  Clock
} from 'lucide-react';
import { Switch } from "../../ui/switch";
import OtherInfoPanel from './OtherInfoPanel';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/select";
import { Input } from "../../ui/input";
import { cn } from "../../../utils";
import FAQPanel from './FAQPanel';
import ItemPricingModal from './ItemPricingModal';

const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const formatTimeDisplay = (time24) => {
  if (!time24) return '';
  try {
    const [hours, minutes] = time24.split(':');
    const date = new Date();
    date.setHours(parseInt(hours), parseInt(minutes));
    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    }).toLowerCase();
  } catch (error) {
    console.error('Error formatting time:', error);
    return '';
  }
};

const TIME_OPTIONS = Array.from({ length: 48 }).map((_, i) => {
  const hour = Math.floor(i / 2);
  const minute = i % 2 === 0 ? '00' : '30';
  const time24 = `${hour.toString().padStart(2, '0')}:${minute}`;
  return {
    value: time24,
    label: formatTimeDisplay(time24)
  };
});

const subServiceCardVariants = {
  initial: { opacity: 0, y: 10, scale: 0.98 },
  animate: { 
    opacity: 1, y: 0, scale: 1,
    transition: { duration: 0.4, ease: [0.16,1,0.3,1] }
  },
  exit: {
    opacity: 0, y: -10, scale: 0.98,
    transition: { duration: 0.3 }
  }
};

const selectionIndicatorVariants = {
  initial: { opacity: 0, scale: 0.98 },
  animate: { 
    opacity: 1, scale: 1,
    transition: { duration: 0.3, ease: [0.16, 1, 0.3, 1] }
  }
};

const SubServiceCard = ({ icon: Icon, title, isActive, isSelected, onToggle, onClick }) => (
  <motion.div
    variants={subServiceCardVariants}
    initial="initial"
    animate="animate"
    exit="exit"
    onClick={onClick}
    className={`relative p-4 rounded-lg border transition-colors duration-200 cursor-pointer ${
      isActive
        ? 'border-gray-300 hover:border-gray-400 bg-white'
        : 'border-gray-200 bg-gray-50/50'
    }`}
  >
    <div className="flex items-center justify-between gap-3">
      <div className="flex items-center gap-3">
        <Icon className={`w-5 h-5 ${
          isActive
            ? 'text-gray-500'
            : 'text-gray-300'
        }`} />
        <span className={`text-sm font-medium ${
          isActive
            ? 'text-gray-600'
            : 'text-gray-400'
        }`}>
          {title}
        </span>
      </div>
      <div 
        onClick={(e) => { e.stopPropagation(); onToggle(!isActive); }}
        className="transition-opacity duration-200"
      >
        <Switch
          checked={isActive}
          className={isActive ? "" : "opacity-50"}
        />
      </div>
    </div>

    <AnimatePresence>
      {isSelected && (
        <motion.div 
          layoutId="sub-service-selection-indicator" 
          variants={selectionIndicatorVariants}
          initial="initial"
          animate="animate"
          className="absolute inset-0 rounded-lg border-2 border-gray-900 pointer-events-none"
        />
      )}
    </AnimatePresence>
  </motion.div>
);

const TabButton = ({ active, icon: Icon, label, onClick }) => (
  <motion.button
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    onClick={onClick}
    className={cn(
      "flex items-center gap-2 px-4 py-2 rounded-md transition-colors",
      active 
        ? "bg-gray-900 text-white" 
        : "bg-gray-100 text-gray-600 hover:bg-gray-200"
    )}
  >
    <Icon className="w-4 h-4" />
    <span className="text-sm font-medium">{label}</span>
  </motion.button>
);

const DropOffPanel = ({
  selectedLocation,
  onUpdateLocation,
  isServiceActive
}) => {
  const [rawInputValues, setRawInputValues] = useState({
    laundry: {
      regularPerPound: '',
      sameDayPrice: '',
      minimumAmount: '',
      turnaroundHours: ''  // Added this
    },
    dryCleaning: {
      items: {},
      sameDayAdditional: '',
      minimumAmount: '',
      turnaroundHours: ''  // Added this
    }
  });
  const services = selectedLocation.services || {
    selfServe: { active: true, days: {} },
    dropOff: {
      active: false,
      laundry: {
        active: false,
        sameAsStoreHours: true,
        days: {},
        turnaroundHours: 24,
        pricing: {
          regularPerPound: 0.0,
          sameDay: {
            enabled: false,
            cutoff: '12:00',
            pricePerPound: 0.0
          }
        }
      },
      dryCleaning: {
        active: false,
        sameAsStoreHours: true,
        days: {},
        turnaroundHours: 48,
        pricing: {
          items: [],
          sameDay: {
            enabled: false,
            cutoff: '12:00',
            additionalCost: 0.0
          }
        }
      }
    },
    pickupDelivery: { active: false }
  };

  const dropOff = services.dropOff || {
    active: false,
    laundry: {
      active: false,
      sameAsStoreHours: true,
      days: {},
      turnaroundHours: 24,
      pricing: {
        regularPerPound: 0.0,
        sameDay: { enabled: false, cutoff: '12:00', pricePerPound: 0.0 }
      }
    },
    dryCleaning: {
      active: false,
      sameAsStoreHours: true,
      days: {},
      turnaroundHours: 48,
      pricing: {
        items: [],
        sameDay: { enabled: false, cutoff: '12:00', additionalCost: 0.0 }
      }
    }
  };

  const [laundryData, setLaundryData] = useState({
    active: dropOff.laundry?.active ?? false,
    sameAsStoreHours: dropOff.laundry?.sameAsStoreHours ?? true,
    days: dropOff.laundry?.days ?? {},
    turnaroundHours: dropOff.laundry?.turnaroundHours ?? 24,
    pricing: {
      regularPerPound: dropOff.laundry?.pricing?.regularPerPound ?? 0.0,
      regularPriceType: 'custom',
      regularPriceIncrease: 0,
      minimumOrder: {
        enabled: dropOff.laundry?.pricing?.minimumOrder?.enabled ?? false,
        amount: dropOff.laundry?.pricing?.minimumOrder?.amount ?? 0,
        isDollarBased: dropOff.laundry?.pricing?.minimumOrder?.isDollarBased ?? true,
        priceType: 'custom',
        priceIncrease: 0
      },
      specialtyItems: {
        enabled: dropOff.laundry?.pricing?.specialtyItems?.enabled ?? false,
        items: dropOff.laundry?.pricing?.specialtyItems?.items || [],
        priceType: dropOff.laundry?.pricing?.specialtyItems?.priceType || 'custom',
        priceIncrease: dropOff.laundry?.pricing?.specialtyItems?.priceIncrease || 0
      },
      extras: {
        enabled: dropOff.laundry?.pricing?.extras?.enabled ?? false,
        items: dropOff.laundry?.pricing?.extras?.items || [],
        priceType: dropOff.laundry?.pricing?.extras?.priceType || 'custom',
        priceIncrease: dropOff.laundry?.pricing?.extras?.priceIncrease || 0
      }
    }
  });

  const [dryCleaningData, setDryCleaningData] = useState({
    active: dropOff.dryCleaning?.active ?? false,
    sameAsStoreHours: dropOff.dryCleaning?.sameAsStoreHours ?? true,
    days: dropOff.dryCleaning?.days ?? {},
    turnaroundHours: dropOff.dryCleaning?.turnaroundHours ?? 48,
    pricing: {
      items: dropOff.dryCleaning?.pricing?.items ?? [],
      minimumOrder: {
        enabled: dropOff.dryCleaning?.pricing?.minimumOrder?.enabled ?? false,
        amount: dropOff.dryCleaning?.pricing?.minimumOrder?.amount ?? 0,
        isDollarBased: dropOff.dryCleaning?.pricing?.minimumOrder?.isDollarBased ?? true,
        priceType: 'custom',
        priceIncrease: 0
      }
    }
  });

  const [selectedSubService, setSelectedSubService] = useState(
    laundryData.active ? 'laundry' : (dryCleaningData.active ? 'dryCleaning' : 'laundry')
  );

  const [laundryTab, setLaundryTab] = useState('schedule');
  const [dryCleaningTab, setDryCleaningTab] = useState('schedule');
const [laundryOtherInfo, setLaundryOtherInfo] = useState(
  selectedLocation?.services?.dropOff?.laundry?.otherInfo || []
);
const [dryCleaningOtherInfo, setDryCleaningOtherInfo] = useState(
  selectedLocation?.services?.dropOff?.dryCleaning?.otherInfo || []
);
  const [laundryOtherInfoTab, setLaundryOtherInfoTab] = useState('additional');
  const [dryCleaningOtherInfoTab, setDryCleaningOtherInfoTab] = useState('additional');

  const [showSpecialtyModal, setShowSpecialtyModal] = useState(false);
  const [showExtrasModal, setShowExtrasModal] = useState(false);

  useEffect(() => {
    setRawInputValues({
      laundry: {
        regularPerPound: selectedLocation?.services?.dropOff?.laundry?.pricing?.regularPerPound?.toString() || '',
        sameDayPrice: selectedLocation?.services?.dropOff?.laundry?.pricing?.sameDay?.pricePerPound?.toString() || '',
        minimumAmount: selectedLocation?.services?.dropOff?.laundry?.pricing?.minimumOrder?.amount?.toString() || '',
        turnaroundHours: selectedLocation?.services?.dropOff?.laundry?.turnaroundHours?.toString() || ''  // Added this
      },
      dryCleaning: {
        items: {},
        sameDayAdditional: selectedLocation?.services?.dropOff?.dryCleaning?.pricing?.sameDay?.additionalCost?.toString() || '',
        minimumAmount: selectedLocation?.services?.dropOff?.dryCleaning?.pricing?.minimumOrder?.amount?.toString() || '',
        turnaroundHours: selectedLocation?.services?.dropOff?.dryCleaning?.turnaroundHours?.toString() || ''  // Added this
      }
    });
  }, [selectedLocation]);

  // Add an effect to update laundryData when selectedLocation changes
  useEffect(() => {
    const dropOffLaundry = selectedLocation?.services?.dropOff?.laundry;
    if (dropOffLaundry) {
      setLaundryData(prev => ({
        ...prev,
        active: dropOffLaundry.active ?? false,
        sameAsStoreHours: dropOffLaundry.sameAsStoreHours ?? true,
        days: dropOffLaundry.days ?? {},
        turnaroundHours: dropOffLaundry.turnaroundHours ?? 24,
        pricing: {
          ...prev.pricing,
          regularPerPound: dropOffLaundry.pricing?.regularPerPound ?? 0.0,
          minimumOrder: {
            enabled: dropOffLaundry.pricing?.minimumOrder?.enabled ?? false,
            amount: dropOffLaundry.pricing?.minimumOrder?.amount ?? 0,
            isDollarBased: dropOffLaundry.pricing?.minimumOrder?.isDollarBased ?? true,
            priceType: dropOffLaundry.pricing?.minimumOrder?.priceType || 'custom',
            priceIncrease: dropOffLaundry.pricing?.minimumOrder?.priceIncrease || 0
          },
          specialtyItems: {
            enabled: dropOffLaundry.pricing?.specialtyItems?.enabled ?? false,
            items: dropOffLaundry.pricing?.specialtyItems?.items || [],
            priceType: dropOffLaundry.pricing?.specialtyItems?.priceType || 'custom',
            priceIncrease: dropOffLaundry.pricing?.specialtyItems?.priceIncrease || 0
          },
          extras: {
            enabled: dropOffLaundry.pricing?.extras?.enabled ?? false,
            items: dropOffLaundry.pricing?.extras?.items || [],
            priceType: dropOffLaundry.pricing?.extras?.priceType || 'custom',
            priceIncrease: dropOffLaundry.pricing?.extras?.priceIncrease || 0
          }
        }
      }));
    }
  }, [selectedLocation]);

  if (!isServiceActive) {
    return (
      <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
        <p className="text-sm text-gray-600 text-center">
          Enable Drop-off service to configure its settings
        </p>
      </div>
    );
  }

  // This function updates the dropOff object in the location and then triggers onUpdateLocation
  const handleUpdateDropOff = (updates) => {
    const updated = {
      ...selectedLocation,
      services: {
        ...services,
        dropOff: {
          ...dropOff,
          ...updates
        }
      }
    };
    // Call the onUpdateLocation to ensure changes are saved to Supabase
    onUpdateLocation(updated);
  };

  const handleLaundryUpdate = (updates) => {
    const newLaundry = { ...laundryData, ...updates };
    setLaundryData(newLaundry);
    handleUpdateDropOff({ laundry: newLaundry });
  };

  const handleDryCleaningUpdate = (updates) => {
    const newDryCleaning = { ...dryCleaningData, ...updates };
    setDryCleaningData(newDryCleaning);
    handleUpdateDropOff({ dryCleaning: newDryCleaning });
  };

  const updateDay = (dayKey, serviceType, update) => {
    const data = serviceType === 'laundry' ? laundryData : dryCleaningData;
    const daysObj = data.days || {};
    const dayData = daysObj[dayKey] || {
      isOpen: true,
      type: 'specific',
      timeRanges: [{ start: '09:00', end: '17:00' }]
    };
    const newDays = {
      ...daysObj,
      [dayKey]: {
        ...dayData,
        ...update
      }
    };
    serviceType === 'laundry'
      ? handleLaundryUpdate({ days: newDays })
      : handleDryCleaningUpdate({ days: newDays });
  };

  const handleAddItem = () => {
    const newItem = { name: '', price: 0.0 };
    const newItems = [...(dryCleaningData.pricing?.items || []), newItem];
    handleDryCleaningUpdate({ 
      pricing: { 
        ...dryCleaningData.pricing, 
        items: newItems 
      } 
    });
  };

  const handleUpdateItem = (index, field, value) => {
    const newItems = [...dryCleaningData.pricing.items];
    newItems[index] = { 
      ...newItems[index], 
      [field]: field === 'price' ? parseFloat(value) || 0 : value 
    };
    handleDryCleaningUpdate({ 
      pricing: { 
        ...dryCleaningData.pricing, 
        items: newItems 
      } 
    });
  };

  const handleDeleteItem = (index) => {
    const newItems = dryCleaningData.pricing.items.filter((_, i) => i !== index);
    handleDryCleaningUpdate({ 
      pricing: { 
        ...dryCleaningData.pricing, 
        items: newItems 
      } 
    });
  };

  const renderDayHoursEditor = (serviceType) => {
    const data = serviceType === 'laundry' ? laundryData : dryCleaningData;
    const daysObj = data.days || {};

    return (
      <div className="space-y-4 mt-4">
        {DAYS_OF_WEEK.map((day) => {
          const dayKey = day.toLowerCase();
          const dayData = daysObj[dayKey] || {
            isOpen: true,
            type: 'specific',
            timeRanges: [{ start: '09:00', end: '17:00' }]
          };
          const isOpen = dayData.isOpen ?? true;
          const isAllDay = dayData.type === 'allDay';
          const timeRange = dayData.timeRanges?.[0] || { start: '09:00', end: '17:00' };

          return (
            <motion.div 
              key={day} 
              className="border-b border-gray-200 pb-4"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className="flex items-center justify-between">
                <span className="font-medium text-gray-700">{day}</span>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => updateDay(dayKey, serviceType, { isOpen: !isOpen })}
                    className={cn(
                      "px-3 py-1.5 text-sm font-medium rounded-md transition-colors",
                      isOpen 
                        ? "bg-gray-900 text-white hover:bg-gray-800" 
                        : "bg-gray-100 text-gray-500 hover:bg-gray-200"
                    )}
                  >
                    {isOpen ? 'Open' : 'Closed'}
                  </button>

                  {isOpen && (
                    <button
                      onClick={() => updateDay(dayKey, serviceType, {
                        type: isAllDay ? 'specific' : 'allDay',
                        timeRanges: isAllDay ? [{ start: '09:00', end: '17:00' }] : undefined
                      })}
                      className={cn(
                        "inline-flex items-center gap-2 h-9 px-3 rounded-md transition-all duration-200",
                        isAllDay 
                          ? "bg-gray-900 text-white hover:bg-gray-800" 
                          : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                      )}
                    >
                      <Calendar className="w-4 h-4" />
                      {isAllDay ? 'All Day' : 'Custom'}
                    </button>
                  )}
                </div>
              </div>

              <AnimatePresence>
                {isOpen && !isAllDay && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{
                      duration: 0.3,
                      ease: [0.16, 1, 0.3, 1]
                    }}
                    className="flex items-center gap-4 mt-4"
                  >
                    <div className="flex-1 space-y-2">
                      <label className="text-sm text-gray-600">Opening Time</label>
                      <Select
                        value={timeRange.start}
                        onValueChange={(value) => updateDay(dayKey, serviceType, { 
                          timeRanges: [{ ...timeRange, start: value }] 
                        })}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue>
                            {(value) => formatTimeDisplay(value)}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent className="max-h-[300px] overflow-y-auto no-scrollbar">
                          {TIME_OPTIONS.map((time) => (
                            <SelectItem key={time.value} value={time.value}>
                              {time.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>

                    <div className="flex items-center self-end pb-2">
                      <span className="text-sm font-medium text-gray-400">to</span>
                    </div>

                    <div className="flex-1 space-y-2">
                      <label className="text-sm text-gray-600">Closing Time</label>
                      <Select
                        value={timeRange.end}
                        onValueChange={(value) => updateDay(dayKey, serviceType, { 
                          timeRanges: [{ ...timeRange, end: value }] 
                        })}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue>
                            {(value) => formatTimeDisplay(value)}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent className="max-h-[300px] overflow-y-auto no-scrollbar">
                          {TIME_OPTIONS.map((time) => (
                            <SelectItem key={time.value} value={time.value}>
                              {time.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          );
        })}
      </div>
    );
  };

  const renderLaundrySchedule = () => {
    const sameDay = laundryData.pricing?.sameDay || {
      enabled: false,
      cutoff: '12:00',
      pricePerPound: 0.0
    };

    return (
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <span className="text-sm text-gray-700">Use Same Hours as Store?</span>
          <Switch
            checked={laundryData.sameAsStoreHours}
            onCheckedChange={(checked) => handleLaundryUpdate({ sameAsStoreHours: checked })}
          />
        </div>

        <AnimatePresence>
          {!laundryData.sameAsStoreHours && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
            >
              {renderDayHoursEditor('laundry')}
            </motion.div>
          )}
        </AnimatePresence>

        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">Turnaround Time (hours)</label>
          <motion.div whileHover={{ scale: 1.01 }} whileTap={{ scale: 0.99 }}>
            <Input
              type="number"
              value={rawInputValues.laundry.turnaroundHours || laundryData.turnaroundHours?.toString()}
              onChange={(e) => {
                const newValue = e.target.value;
                setRawInputValues(prev => ({
                  ...prev,
                  laundry: {
                    ...prev.laundry,
                    turnaroundHours: newValue
                  }
                }));
                handleLaundryUpdate({ 
                  turnaroundHours: newValue === '' ? '' : parseInt(newValue)
                });
              }}
              onBlur={(e) => {
                const value = e.target.value;
                if (value === '' || isNaN(parseInt(value))) {
                  const newValue = '24';
                  setRawInputValues(prev => ({
                    ...prev,
                    laundry: {
                      ...prev.laundry,
                      turnaroundHours: newValue
                    }
                  }));
                  handleLaundryUpdate({ turnaroundHours: 24 });
                }
              }}
              className="w-24"
            />
          </motion.div>
        </div>
      </div>
    );
  };

  const renderLaundryPricing = () => {
    const pricing = laundryData.pricing || {
      regularPerPound: 0,
      minimumOrder: {
        enabled: false,
        amount: 0,
        isDollarBased: true
      },
      specialtyItems: {
        enabled: false,
        items: []
      },
      extras: {
        enabled: false,
        items: []
      }
    };

    return (
      <div className="space-y-4">
        {/* Regular Price */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-3">
            <DollarSign className="w-5 h-5 text-gray-400" />
            <div className="flex flex-col">
              <span className="text-sm font-medium text-gray-700">Regular Price (per lb)</span>
              <span className="text-xs text-gray-500">Base price for regular laundry service</span>
            </div>
          </div>
          <div className="flex items-center gap-1">
            <span className="text-sm text-gray-400">$</span>
            <Input
              type="number"
              value={rawInputValues.laundry.regularPerPound || pricing.regularPerPound?.toString()}
              onChange={(e) => {
                const newValue = e.target.value;
                setRawInputValues(prev => ({
                  ...prev,
                  laundry: {
                    ...prev.laundry,
                    regularPerPound: newValue
                  }
                }));
                handleLaundryUpdate({
                  pricing: {
                    ...pricing,
                    regularPerPound: newValue === '' ? '' : parseFloat(newValue)
                  }
                });
              }}
              onBlur={(e) => {
                const value = e.target.value;
                if (value === '' || isNaN(parseFloat(value))) {
                  const newValue = '0';
                  setRawInputValues(prev => ({
                    ...prev,
                    laundry: {
                      ...prev.laundry,
                      regularPerPound: newValue
                    }
                  }));
                  handleLaundryUpdate({
                    pricing: {
                      ...pricing,
                      regularPerPound: 0
                    }
                  });
                }
              }}
              className="w-24"
              min="0"
              step="0.01"
            />
          </div>
        </div>

        {/* Minimum Order */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <DollarSign className="w-5 h-5 text-gray-400" />
            <span className="text-sm font-medium text-gray-700">Minimum Order Required</span>
          </div>
          <Switch
            checked={pricing.minimumOrder?.enabled || false}
            onCheckedChange={(checked) => {
              handleLaundryUpdate({
                pricing: {
                  ...pricing,
                  minimumOrder: {
                    ...pricing.minimumOrder,
                    enabled: checked
                  }
                }
              });
            }}
          />
        </div>

        <AnimatePresence>
          {pricing.minimumOrder?.enabled && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="space-y-4"
            >
              <div className="p-4 bg-gray-50 rounded-lg">
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-700">Minimum Order</label>
                    <div className="flex items-center gap-1">
                      {pricing.minimumOrder.isDollarBased && <span className="text-sm text-gray-400">$</span>}
                      <Input
                        type="number"
                        value={rawInputValues.laundry.minimumAmount || pricing.minimumOrder?.amount?.toString()}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRawInputValues(prev => ({
                            ...prev,
                            laundry: {
                              ...prev.laundry,
                              minimumAmount: newValue
                            }
                          }));
                          handleLaundryUpdate({
                            pricing: {
                              ...pricing,
                              minimumOrder: {
                                ...pricing.minimumOrder,
                                amount: newValue === '' ? '' : parseFloat(newValue)
                              }
                            }
                          });
                        }}
                        onBlur={(e) => {
                          const value = e.target.value;
                          if (value === '' || isNaN(parseFloat(value))) {
                            const newValue = '0';
                            setRawInputValues(prev => ({
                              ...prev,
                              laundry: {
                                ...prev.laundry,
                                minimumAmount: newValue
                              }
                            }));
                            handleLaundryUpdate({
                              pricing: {
                                ...pricing,
                                minimumOrder: {
                                  ...pricing.minimumOrder,
                                  amount: 0
                                }
                              }
                            });
                          }
                        }}
                        className="w-24"
                        min="0"
                        step="0.01"
                      />
                      {!pricing.minimumOrder.isDollarBased && <span className="text-sm text-gray-400">lbs</span>}
                    </div>
                  </div>

                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-700">Amount Type</label>
                    <Select
                      value={pricing.minimumOrder.isDollarBased.toString()}
                      onValueChange={(value) => handleLaundryUpdate({
                        pricing: {
                          ...pricing,
                          minimumOrder: {
                            ...pricing.minimumOrder,
                            isDollarBased: value === "true"
                          }
                        }
                      })}
                    >
                      <SelectTrigger>
                        <SelectValue>
                          {pricing.minimumOrder.isDollarBased ? 'Dollars ($)' : 'Pounds (lbs)'}
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="true">Dollars ($)</SelectItem>
                        <SelectItem value="false">Pounds (lbs)</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Specialty Items */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-3">
            <Settings className="w-5 h-5 text-gray-400" />
            <div className="flex flex-col">
              <span className="text-sm font-medium text-gray-700">
                Specialty Item Pricing
              </span>
              {pricing?.specialtyItems?.enabled && (
                <span className="text-xs text-gray-500">
                  {pricing?.specialtyItems?.items.length} items configured
                </span>
              )}
            </div>
          </div>
          <div className="flex items-center gap-4">
            {pricing?.specialtyItems?.enabled && (
              <motion.button
                onClick={() => setShowSpecialtyModal(true)}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="text-sm text-gray-600 hover:text-gray-900"
              >
                Configure
              </motion.button>
            )}
            <Switch
              checked={pricing?.specialtyItems?.enabled}
              onCheckedChange={(checked) => {
                handleLaundryUpdate({
                  pricing: {
                    ...pricing,
                    specialtyItems: {
                      ...pricing.specialtyItems,
                      enabled: checked,
                      items: checked ? (pricing.specialtyItems?.items || []) : []
                    }
                  }
                });
              }}
            />
          </div>
        </div>

        {/* Extras */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-3">
            <Plus className="w-5 h-5 text-gray-400" />
            <div className="flex flex-col">
              <span className="text-sm font-medium text-gray-700">Extras Pricing</span>
              {pricing?.extras?.enabled && (
                <span className="text-xs text-gray-500">
                  {pricing?.extras?.items.length} extras configured
                </span>
              )}
            </div>
          </div>
          <div className="flex items-center gap-4">
            {pricing?.extras?.enabled && (
              <motion.button
                onClick={() => setShowExtrasModal(true)}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="text-sm text-gray-600 hover:text-gray-900"
              >
                Configure
              </motion.button>
            )}
            <Switch
              checked={pricing?.extras?.enabled}
              onCheckedChange={(checked) => {
                handleLaundryUpdate({
                  pricing: {
                    ...pricing,
                    extras: {
                      ...pricing.extras,
                      enabled: checked,
                      items: checked ? (pricing.extras?.items || []) : []
                    }
                  }
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderDryCleaningSchedule = () => {
    const pricing = dryCleaningData.pricing || { 
      items: [], 
      sameDay: { enabled: false, cutoff: '12:00', additionalCost: 0.0 } 
    };
    const sameDay = pricing.sameDay || { enabled: false, cutoff: '12:00', additionalCost: 0.0 };

    return (
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <span className="text-sm text-gray-700">Use Same Hours as Store?</span>
          <Switch
            checked={dryCleaningData.sameAsStoreHours}
            onCheckedChange={(checked) => handleDryCleaningUpdate({ sameAsStoreHours: checked })}
          />
        </div>

        <AnimatePresence>
          {!dryCleaningData.sameAsStoreHours && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
            >
              {renderDayHoursEditor('dryCleaning')}
            </motion.div>
          )}
        </AnimatePresence>

        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">Turnaround Time (hours)</label>
          <motion.div whileHover={{ scale: 1.01 }} whileTap={{ scale: 0.99 }}>
            <Input
              type="number"
              value={rawInputValues.dryCleaning.turnaroundHours || dryCleaningData.turnaroundHours?.toString()}
              onChange={(e) => {
                const newValue = e.target.value;
                setRawInputValues(prev => ({
                  ...prev,
                  dryCleaning: {
                    ...prev.dryCleaning,
                    turnaroundHours: newValue
                  }
                }));
                handleDryCleaningUpdate({ 
                  turnaroundHours: newValue === '' ? '' : parseInt(newValue)
                });
              }}
              onBlur={(e) => {
                const value = e.target.value;
                if (value === '' || isNaN(parseInt(value))) {
                  const newValue = '48';
                  setRawInputValues(prev => ({
                    ...prev,
                    dryCleaning: {
                      ...prev.dryCleaning,
                      turnaroundHours: newValue
                    }
                  }));
                  handleDryCleaningUpdate({ turnaroundHours: 48 });
                }
              }}
              className="w-24"
            />
          </motion.div>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-sm text-gray-700">Offer Same-Day Service?</span>
          <Switch
            checked={sameDay.enabled}
            onCheckedChange={(checked) => handleDryCleaningUpdate({ 
              pricing: { 
                ...pricing, 
                sameDay: { ...sameDay, enabled: checked } 
              } 
            })}
          />
        </div>

        <AnimatePresence>
          {sameDay.enabled && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="space-y-2"
              transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
            >
              <label className="text-sm text-gray-700">Same-Day Cutoff Time</label>
              <Select
                value={sameDay.cutoff}
                onValueChange={(value) => handleDryCleaningUpdate({ 
                  pricing: { 
                    ...pricing, 
                    sameDay: { ...sameDay, cutoff: value } 
                  } 
                })}
              >
                <SelectTrigger className="w-full">
                  <SelectValue>{(value) => formatTimeDisplay(value)}</SelectValue>
                </SelectTrigger>
                <SelectContent className="max-h-[300px] overflow-y-auto no-scrollbar">
                  {TIME_OPTIONS.map((time) => (
                    <SelectItem key={time.value} value={time.value}>
                      {time.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

  const renderDryCleaningPricing = () => {
    const pricing = dryCleaningData.pricing || { 
      items: [], 
      minimumOrder: {
        enabled: false,
        amount: 0,
        isDollarBased: true,
        priceType: 'custom',
        priceIncrease: 0
      },
      sameDay: { 
        enabled: false, 
        cutoff: '12:00', 
        additionalCost: 0.0,
        priceType: 'custom',
        priceIncrease: 0
      } 
    };
    const sameDay = pricing.sameDay || { 
      enabled: false, 
      cutoff: '12:00', 
      additionalCost: 0.0,
      priceType: 'custom',
      priceIncrease: 0
    };

    return (
      <div className="space-y-4">
        {/* Minimum Order */}
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center gap-3">
            <DollarSign className="w-5 h-5 text-gray-400" />
            <div className="space-y-1">
              <span className="text-sm font-medium text-gray-700">Minimum Order Required</span>
              {pricing.minimumOrder.enabled && (
                <span className="text-xs text-gray-500 block">
                  {pricing.minimumOrder.isDollarBased ? 'In dollars' : 'In number of items'}
                </span>
              )}
            </div>
          </div>
          <Switch
            checked={pricing.minimumOrder?.enabled || false}
            onCheckedChange={(checked) => {
              handleDryCleaningUpdate({
                pricing: {
                  ...pricing,
                  minimumOrder: {
                    ...pricing.minimumOrder,
                    enabled: checked
                  }
                }
              });
            }}
          />
        </div>

        <AnimatePresence>
          {pricing.minimumOrder?.enabled && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="space-y-4"
            >
              <div className="p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <DollarSign className="w-5 h-5 text-gray-400" />
                    <div className="flex flex-col">
                      <span className="text-sm font-medium text-gray-700">Minimum Order Amount</span>
                      <span className="text-xs text-gray-500">Set the minimum order requirement</span>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <Select
                      value={pricing.minimumOrder.priceType || 'custom'}
                      onValueChange={(value) => {
                        handleDryCleaningUpdate({
                          pricing: {
                            ...pricing,
                            minimumOrder: {
                              ...pricing.minimumOrder,
                              priceType: value,
                              amount: value === 'custom' ? pricing.minimumOrder.amount : 0,
                              priceIncrease: value === 'fixed_increase' ? (pricing.minimumOrder.priceIncrease || 0) : 0
                            }
                          }
                        });
                      }}
                    >
                      <SelectTrigger className="w-[180px]">
                        <SelectValue>
                          {pricing.minimumOrder.priceType === 'custom' ? 'Custom Amount' : 'Fixed Increase'}
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="fixed_increase">Fixed Increase</SelectItem>
                        <SelectItem value="custom">Custom Amount</SelectItem>
                      </SelectContent>
                    </Select>
                  <div className="flex items-center gap-1">
                      {pricing.minimumOrder.isDollarBased && <span className="text-sm text-gray-400">$</span>}
                    <Input
                      type="number"
                        value={rawInputValues.dryCleaning.minimumAmount || (pricing.minimumOrder.priceType === 'custom' ? pricing.minimumOrder.amount : pricing.minimumOrder.priceIncrease)?.toString()}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRawInputValues(prev => ({
                          ...prev,
                          dryCleaning: {
                            ...prev.dryCleaning,
                            minimumAmount: newValue
                          }
                        }));
                        handleDryCleaningUpdate({
                          pricing: {
                            ...pricing,
                            minimumOrder: {
                              ...pricing.minimumOrder,
                                [pricing.minimumOrder.priceType === 'custom' ? 'amount' : 'priceIncrease']: newValue === '' ? '' : parseFloat(newValue)
                            }
                          }
                        });
                      }}
                      onBlur={(e) => {
                        const value = e.target.value;
                        if (value === '' || isNaN(parseFloat(value))) {
                          const newValue = '0';
                          setRawInputValues(prev => ({
                            ...prev,
                            dryCleaning: {
                              ...prev.dryCleaning,
                              minimumAmount: newValue
                            }
                          }));
                          handleDryCleaningUpdate({
                            pricing: {
                              ...pricing,
                              minimumOrder: {
                                ...pricing.minimumOrder,
                                  [pricing.minimumOrder.priceType === 'custom' ? 'amount' : 'priceIncrease']: 0
                              }
                            }
                          });
                        }
                      }}
                      className="w-24"
                      min="0"
                      step="0.01"
                    />
                      {!pricing.minimumOrder.isDollarBased && <span className="text-sm text-gray-400">items</span>}
                      {pricing.minimumOrder.priceType === 'fixed_increase' && (
                        <span className="text-sm text-gray-500">
                          = ${(pricing.minimumOrder.amount + (pricing.minimumOrder.priceIncrease || 0)).toFixed(2)}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <label className="text-sm text-gray-700">Amount Type</label>
                  <Select
                    value={pricing.minimumOrder.isDollarBased.toString()}
                    onValueChange={(value) => handleDryCleaningUpdate({
                      pricing: {
                        ...pricing,
                        minimumOrder: {
                          ...pricing.minimumOrder,
                          isDollarBased: value === "true"
                        }
                      }
                    })}
                  >
                    <SelectTrigger>
                      <SelectValue>
                        {pricing.minimumOrder.isDollarBased ? 'Dollars ($)' : 'Number of Items'}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="true">Dollars ($)</SelectItem>
                      <SelectItem value="false">Number of Items</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        
        {/* Dry Cleaning Items */}
        <div className="flex items-center justify-between">
          <span className="text-sm text-gray-700">Dry Cleaning Items & Prices</span>
          <motion.button
            onClick={handleAddItem}
            className="flex items-center gap-1 px-3 py-1.5 bg-gray-900 text-white text-sm rounded-md hover:bg-gray-800"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <Plus className="w-4 h-4" />
            Add Item
          </motion.button>
        </div>

        {pricing.items.length === 0 && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="p-4 bg-white border border-gray-200 rounded-lg text-center text-sm text-gray-500"
          >
            No dry cleaning items added yet.
          </motion.div>
        )}

        <AnimatePresence>
          {pricing.items.map((item, index) => (
            <motion.div 
              key={index}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="flex items-center gap-4 p-4 bg-white border border-gray-200 rounded-lg"
              transition={{ duration: 0.2 }}
            >
              <motion.input
                type="text"
                placeholder="Item Name"
                value={item.name}
                onChange={(e) => handleUpdateItem(index, 'name', e.target.value)}
                className="flex-1 px-3 py-1.5 bg-gray-50 border border-gray-200 rounded-md text-sm"
                whileFocus={{ scale: 1.01 }}
              />
              <div className="flex items-center gap-1">
                <span className="text-sm text-gray-400">$</span>
                <motion.div whileHover={{ scale: 1.01 }} whileTap={{ scale: 0.99 }}>
                  <Input
                    type="number"
                    value={rawInputValues.dryCleaning.items[`${index}_price`] ?? item.price?.toString()}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRawInputValues(prev => ({
                        ...prev,
                        dryCleaning: {
                          ...prev.dryCleaning,
                          items: {
                            ...prev.dryCleaning.items,
                            [`${index}_price`]: newValue
                          }
                        }
                      }));
                      handleUpdateItem(index, 'price', newValue);
                    }}
                    onBlur={(e) => {
                      const value = e.target.value;
                      if (value === '' || isNaN(parseFloat(value))) {
                        const newValue = '0';
                        setRawInputValues(prev => ({
                          ...prev,
                          dryCleaning: {
                            ...prev.dryCleaning,
                            items: {
                              ...prev.dryCleaning.items,
                              [`${index}_price`]: newValue
                            }
                          }
                        }));
                        handleUpdateItem(index, 'price', 0);
                      }
                    }}
                    className="w-24"
                    min="0"
                    step="0.01"
                  />
                </motion.div>
              </div>
              <motion.button
                onClick={() => handleDeleteItem(index)}
                className="p-1.5 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-full transition-colors"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <Trash2 className="w-4 h-4" />
              </motion.button>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    );
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 gap-4 relative pb-4">
        <SubServiceCard
          icon={Droplet}
          title="Drop-off Laundry"
          isActive={laundryData.active}
          isSelected={selectedSubService === 'laundry'}
          onToggle={(checked) => handleLaundryUpdate({ active: checked })}
          onClick={() => setSelectedSubService('laundry')}
        />
        <SubServiceCard
          icon={Sparkles}
          title="Dry Cleaning"
          isActive={dryCleaningData.active}
          isSelected={selectedSubService === 'dryCleaning'}
          onToggle={(checked) => handleDryCleaningUpdate({ active: checked })}
          onClick={() => setSelectedSubService('dryCleaning')}
        />
      </div>

      <AnimatePresence mode="wait">
        {selectedSubService === 'laundry' && laundryData.active && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="flex items-center gap-3"
          >
            <TabButton
              active={laundryTab === 'schedule'}
              icon={Hourglass}
              label="Schedule"
              onClick={() => setLaundryTab('schedule')}
            />
            <TabButton
              active={laundryTab === 'pricing'}
              icon={DollarSign}
              label="Pricing"
              onClick={() => setLaundryTab('pricing')}
            />
            <TabButton
              active={laundryTab === 'otherInfo'}
              icon={Info}
              label="Other Info"
              onClick={() => setLaundryTab('otherInfo')}
            />
          </motion.div>
        )}

        {selectedSubService === 'dryCleaning' && dryCleaningData.active && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="flex items-center gap-3"
          >
            <TabButton
              active={dryCleaningTab === 'schedule'}
              icon={Timer}
              label="Schedule"
              onClick={() => setDryCleaningTab('schedule')}
            />
            <TabButton
              active={dryCleaningTab === 'pricing'}
              icon={DollarSign}
              label="Pricing"
              onClick={() => setDryCleaningTab('pricing')}
            />
            <TabButton
              active={dryCleaningTab === 'otherInfo'}
              icon={Info}
              label="Other Info"
              onClick={() => setDryCleaningTab('otherInfo')}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <div className="bg-white rounded-lg border border-gray-200 p-6">
        <AnimatePresence mode="wait">
          <motion.div
            key={`${selectedSubService}-${laundryTab || dryCleaningTab}`}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{
              duration: 0.2,
              ease: [0.16, 1, 0.3, 1]
            }}
          >
            {selectedSubService === 'laundry' && !laundryData.active && (
              <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
                <p className="text-sm text-gray-600 text-center">
                  Enable Laundry service to configure its settings
                </p>
              </div>
            )}

            {selectedSubService === 'laundry' && laundryData.active && (
              <>
                {laundryTab === 'schedule' && renderLaundrySchedule()}
                {laundryTab === 'pricing' && renderLaundryPricing()}
                {laundryTab === 'otherInfo' && (
                  <div className="space-y-6">
                    <div className="flex items-center gap-3">
                      <TabButton
                        active={laundryOtherInfoTab === 'additional'}
                        icon={Info}
                        label="Additional Info"
                        onClick={() => setLaundryOtherInfoTab('additional')}
                      />
                      <TabButton
                        active={laundryOtherInfoTab === 'faqs'}
                        icon={HelpCircle}
                        label="FAQs"
                        onClick={() => setLaundryOtherInfoTab('faqs')}
                      />
                    </div>

                    <AnimatePresence mode="wait">
                      <motion.div
                        key={laundryOtherInfoTab}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2 }}
                      >
                        {laundryOtherInfoTab === 'additional' && (
                          <OtherInfoPanel
                            value={selectedLocation?.services?.dropOff?.laundry?.otherInfo || []}
                            onChange={(newValue) => {
                              handleLaundryUpdate({
                                otherInfo: newValue
                              });
                            }}
                            title="Additional Wash Dry Fold Information"
                          />
                        )}

                        {laundryOtherInfoTab === 'faqs' && (
                          <FAQPanel
                            faqs={selectedLocation?.services?.dropOff?.laundry?.faqs || []}
                            onChange={(newFaqs) => {
                              handleLaundryUpdate({
                                faqs: newFaqs
                              });
                            }}
                            title="Wash Dry Fold FAQs"
                          />
                        )}
                      </motion.div>
                    </AnimatePresence>
                  </div>
                )}
              </>
            )}

            {selectedSubService === 'dryCleaning' && !dryCleaningData.active && (
              <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
                <p className="text-sm text-gray-600 text-center">
                  Enable Dry Cleaning service to configure its settings
                </p>
              </div>
            )}

            {selectedSubService === 'dryCleaning' && dryCleaningData.active && (
              <>
                {dryCleaningTab === 'schedule' && renderDryCleaningSchedule()}
                {dryCleaningTab === 'pricing' && renderDryCleaningPricing()}
                {dryCleaningTab === 'otherInfo' && (
                  <div className="space-y-6">
                    <div className="flex items-center gap-3">
                      <TabButton
                        active={dryCleaningOtherInfoTab === 'additional'}
                        icon={Info}
                        label="Additional Info"
                        onClick={() => setDryCleaningOtherInfoTab('additional')}
                      />
                      <TabButton
                        active={dryCleaningOtherInfoTab === 'faqs'}
                        icon={HelpCircle}
                        label="FAQs"
                        onClick={() => setDryCleaningOtherInfoTab('faqs')}
                      />
                    </div>

                    <AnimatePresence mode="wait">
                      <motion.div
                        key={dryCleaningOtherInfoTab}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2 }}
                      >
                        {dryCleaningOtherInfoTab === 'additional' && (
                          <OtherInfoPanel
                            value={selectedLocation?.services?.dropOff?.dryCleaning?.otherInfo || []}
                            onChange={(newValue) => {
                              handleDryCleaningUpdate({
                                otherInfo: newValue
                              });
                            }}
                            title="Additional Dry Cleaning Information"
                          />
                        )}

                        {dryCleaningOtherInfoTab === 'faqs' && (
                          <FAQPanel
                            faqs={selectedLocation?.services?.dropOff?.dryCleaning?.faqs || []}
                            onChange={(newFaqs) => {
                              handleDryCleaningUpdate({
                                faqs: newFaqs
                              });
                            }}
                            title="Dry Cleaning FAQs"
                          />
                        )}
                      </motion.div>
                    </AnimatePresence>
                  </div>
                )}
              </>
            )}
          </motion.div>
        </AnimatePresence>
      </div>

      <ItemPricingModal
        isOpen={showSpecialtyModal}
        onClose={() => setShowSpecialtyModal(false)}
        title="Specialty Item Pricing"
        items={laundryData.pricing?.specialtyItems?.items || []}
        showPerField={false}
        onSave={(items) => {
          handleLaundryUpdate({
            pricing: {
              ...laundryData.pricing,
              specialtyItems: {
                ...laundryData.pricing.specialtyItems,
                items
              }
            }
          });
        }}
      />

      <ItemPricingModal
        isOpen={showExtrasModal}
        onClose={() => setShowExtrasModal(false)}
        title="Extras Pricing"
        items={laundryData.pricing?.extras?.items || []}
        showPerField={true}
        onSave={(items) => {
          handleLaundryUpdate({
            pricing: {
              ...laundryData.pricing,
              extras: {
                ...laundryData.pricing.extras,
                items
              }
            }
          });
        }}
      />
    </div>
  );
};

export default DropOffPanel;
